
import React, { useState,useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, Card,InputGroup,Button  } from '@themesberg/react-bootstrap';
import { AuthProvider, useAuth } from '../pages/AuthContext';
// import { Breadcrumb,Button,Col, Row , Nav, Card, Table, Pagination } from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/avatar.png";

import jwt_decode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert';


export default ({ onToggleSidebar }) => {
  const [user, setUser] = useState({})
  const history = useHistory()
  let user_id = JSON.parse(localStorage.getItem('user')) 
  const [licenseDay, setLicenseDay] = useState(null)
  const { logout, token } = useAuth();

  // useEffect(() => {
  //   const checkUserConnected = () => {
  //     try {
  //       const token = localStorage.getItem("accessToken")
  //       console.log('token nav' +token )
  //       const decodedToken = jwt_decode(token);
  //       console.log('decodedToken nav' +JSON.stringify(decodedToken)  )
  //       if(token == null || decodedToken.userId == null) {
  //         localStorage.removeItem('accessToken')
  //         localStorage.removeItem('user')
  //         history.push("/sign-in");
  //         swal({
  //           title: "Session expirée",
  //           text: "Votre session a expirée.",
  //           icon: "warning",
  //           buttons: true,
  //           dangerMode: true,
  //         })
  //         .then((result) => {
  //           console.log(result)
  //         });
  //       }
  //     }catch(err) {
  //       history.push("/sign-in");
  //     }
  //   }
  //   checkUserConnected();
  // }, []);

  useEffect(() => {
    const fetchUser = () => {
      try {
        let user_local = localStorage.getItem("user")
        user_local = JSON.parse(user_local) 
        setUser(user_local)
      }catch(err) {
        console.log(err)
        // history.push("/sign-in");
      }
    }
    fetchUser();
  }, []);

  useEffect(() => {
    const getLicense = () => {
      if(user.company != undefined) {
        fetch(`/api/getlicense`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({company:user.company})}).then(
            response => response.json()
          ).then(
            response => {
               if(response.status == "success") {
                setLicenseDay(parseInt(response.licenseDay))
                } else if(response.status == "license_expired") {
                  localStorage.removeItem('accessToken')
                  localStorage.removeItem('user')
                  history.push("/sign-in");
                  swal({
                    title: "Licence expiré",
                    text: response.message,
                    icon: "error",
                    buttons: true,
                    dangerMode: true,
                  })
                  .then((result) => {
                    // console.log(result)
                  });
               } else if(response.status == "session_expired") {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                history.push("/sign-in");
                swal({
                  title: "Session expiré",
                  text: response.message,
                  icon: "error",
                  buttons: true,
                  dangerMode: true,
                })
                .then((result) => {
                  // console.log(result)
                });
             }else  {
                swal({
                  title: "Erreur",
                  text: response.message,
                  icon: "error",
                  buttons: true,
                  dangerMode: true,
                })
                .then((result) => {
                  // console.log(result)
                });
               }
                
            }
          )
      }
      
    }
    getLicense();
  }, [user]);
  
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };

  const onLogout = () => {
    let accessToken = localStorage.getItem('accessToken')
    logout(accessToken);
  }


  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="dark" className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
         

          <button onClick={onToggleSidebar}  id="sidebar-toggle" type="button" class="sidebar-toggle d-none d-lg-inline-block align-items-center justify-content-center me-3 btn btn-icon-only btn-lg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="toggle-icon">
            <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd">
                </path>

                </svg>
            </button>

             {/* <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form>  */}
          </div>
          <Nav className="align-items-center">
            <div style={{marginRight:50, marginBottom:10}}>
              
              {
              licenseDay != null && licenseDay >= 10 ? (
                <h5 style={{color:"grey"}} className="text-success fw-bold align-top"> {licenseDay}  jours restants dans la période d'essai </h5>
              ) : licenseDay != null && licenseDay < 10 ? (
                <h5 style={{color:"grey"}} className="text-danger fw-bold align-top"> {licenseDay}  jours restants dans la période d'essai </h5>
              ) : (
                ""
              ) }
            </div>
            {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>

                 notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)} 

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown> */}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                {/* <FontAwesomeIcon className="user-avatar md-avatar rounded-circle" icon={faUserTie} className="me-2" /> */}
                  <Image src={Profile3} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{ user?.firstName} { user?.name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold">
                <Card.Link as={Link} className="text-primary fw-bold" to={{ pathname: `/update-user/${user.id}`}}>
                    <FontAwesomeIcon as={Link} className="text-primary fw-bold"   icon={faUserCircle}/> Mon profil
                  </Card.Link>

                  
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Support
                </Dropdown.Item>
                {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                </Dropdown.Item> */}
                {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
                </Dropdown.Item> */}

                <Dropdown.Divider />

                <Dropdown.Item  onClick={onLogout} className="fw-bold">
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Se déconnecter
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
