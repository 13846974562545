import React,{useState, useContext, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faPlus, faTasks, faCloudUploadAlt, faUserShield, faRocket } from '@fortawesome/free-solid-svg-icons';
import {Dropdown,DropdownButton, Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../routes";
import jwt_decode from 'jwt-decode';
import { useHistory } from 'react-router-dom';

import { ProspectionsTable } from "../components/ProspectionsTable";

// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';


export default () => {
  const [ userId, setUserId ]= useState();//useContext(AuthContext);
  const [user,setUser] = useState();
  const history = useHistory()
  const userCon = JSON.parse(localStorage.getItem("user"))  

  // useEffect(() => {
  //   const fetchUser = () => {
  //     try {
  //       // setUser(userCon)
  //       // setUserId(userCon._id)
  //     }catch(err) {
  //       // localStorage.removeItem('accessToken')
  //       // localStorage.removeItem('user')
  //       // history.push("/sign-in");
  //     }
  //   }
  //   fetchUser();
  // }, [userCon]);


  console.log("user", userCon)
  
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item active linkAs={Link} linkProps={{ to: '/forms-project' }}>Projet</Breadcrumb.Item>
          </Breadcrumb>
          {/* <h4>Formulaires</h4>
          <p className="mb-0">Créer votre formulaire selon votre besoin.</p> */}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          {/* <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup> */}
        </div>
      </div>

      {/* <Button variant="primary" size="sm" as={Link} to={Routes.NewProspection.path}><FontAwesomeIcon icon={faPlus} className="me-2" />Créer un projet</Button> */}


      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
        <Col xs={8} md={6} lg={3} xl={4}>
         {
         (userCon != undefined && ['RESPONSABLE','ADMIN'].includes(userCon.role)) ? (
          <DropdownButton id="dropdown-basic-button" title="Créer un projet">
          <Dropdown.Item as={Link} to={Routes.NewProspection.path}>Formulaire</Dropdown.Item>
          {/* <Dropdown.Item as={Link} to={Routes.NewCanal.path}>Canal</Dropdown.Item> */}
        </DropdownButton>

         ) :
         (<></>)
         } 
        
        </Col>
          <Col xs={8} md={6} lg={3} xl={4}>
          </Col>
        </Row>
      </div>
          {/* <ProspectionsTable userId={userId} userData={user}/> */}
        {
          userCon != undefined && userCon._id != null ? (
            <ProspectionsTable userId={userCon._id} userData={userCon}/>
          ) : (
            <p>Loading...</p>
          )
        }
      
    </>
  );
};
