// DynamicDashboard.js
import React, { useState } from 'react';
import { faker } from '@faker-js/faker';
import Chart from 'react-apexcharts';
// import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap components
import { Modal, Button   } from '@themesberg/react-bootstrap';

// Generate Mock Data with Faker
const generateMockData = (numRows = 50) => {
  const data = [];
  for (let i = 0; i < numRows; i++) {
    data.push({
      ID: i + 1,
      Date: faker.date.past().toISOString().split('T')[0], // Random past date
      Name: faker.name.fullName(), // Random name
      Email: faker.internet.email(), // Random email
      Age: faker.datatype.number({ min: 18, max: 65 }), // Random age between 18 and 65
      Income: faker.finance.amount(20000, 100000, 2), // Random income
      Quantity: faker.datatype.number({ min: 1, max: 100 }), // Random quantity
      Country: faker.address.country(), // Random country
      ProductCategory: faker.commerce.department(), // Random product category
      Price: faker.commerce.price(5, 500, 2), // Random price
    });
  }
  return data;
};

export const FormsDashboard = ({formId}) => {
  const [data] = useState(generateMockData(100)); // Mock data with 100 rows
  const [chartType, setChartType] = useState('bar'); // Default chart type
  const [xAxis, setXAxis] = useState('Age'); // Default X-axis variable
  const [yAxis, setYAxis] = useState('Income'); // Default Y-axis variable
  const [aggregation, setAggregation] = useState('Sum'); // Default Y-axis aggregation type
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [showChart, setShowChart] = useState(false); // Show/Hide Chart

  // Get column names for the dropdown options
  const columnNames = Object.keys(data[0]);

  // Handle chart type change
  const handleChartTypeChange = (e) => setChartType(e.target.value);

  // Handle X-axis change
  const handleXAxisChange = (e) => setXAxis(e.target.value);

  // Handle Y-axis change
  const handleYAxisChange = (e) => setYAxis(e.target.value);

  // Handle Y-axis aggregation type change
  const handleAggregationChange = (e) => setAggregation(e.target.value);

  // Group data by the selected X-axis and aggregate Y values
  const getAggregatedData = () => {
    const groupedData = data.reduce((acc, row) => {
      // Get the key for the group based on the selected X-axis
      const groupKey = row[xAxis];
      
      // Initialize the group if not already present
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }

      // Push the Y value into the respective group
      acc[groupKey].push(parseFloat(row[yAxis]) || 0); // Ensure it's a number
      return acc;
    }, {});

    // Calculate the aggregation for each group
    const aggregatedData = Object.keys(groupedData).map((group) => {
      const yValues = groupedData[group];
      
      // Perform the aggregation based on the selected type
      let aggregatedValue;
      switch (aggregation) {
        case 'Count':
          aggregatedValue = yValues.length;
          break;
        case 'Sum':
          aggregatedValue = yValues.reduce((acc, val) => acc + val, 0);
          break;
        case 'Average':
          aggregatedValue = yValues.reduce((acc, val) => acc + val, 0) / yValues.length;
          break;
        default:
          aggregatedValue = yValues.reduce((acc, val) => acc + val, 0);
      }

      return { x: group, y: aggregatedValue };
    });

    return aggregatedData;
  };

  // Prepare data for the chart
  const chartData = {
    series: [
      {
        name: `${aggregation} of ${yAxis}`,
        data: getAggregatedData(),
      },
    ],
    options: {
      chart: {
        type: chartType,
        height: 350,
        zoom: {
          type: 'xy',
        },
      },
      xaxis: {
        title: {
          text: xAxis,
        },
      },
      yaxis: {
        title: {
          text: `${aggregation} of ${yAxis}`,
        },
      },
    },
  };

  // Handle modal open/close
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Handle chart creation
  const handleCreateChart = () => {
    setShowModal(false);
    setShowChart(true);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Dynamic Dashboard</h2>
      
      {/* Create Graph Button */}
      <Button onClick={handleOpenModal} variant="primary">
        Create Graph
      </Button>

      {/* Modal for Graph Creation */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Your Graph</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Dropdown for selecting chart type */}
          <div style={{ marginBottom: '10px' }}>
            <label>
              Chart Type:
              <select value={chartType} onChange={handleChartTypeChange} style={{ marginLeft: '10px' }}>
                <option value="bar">Bar</option>
                <option value="line">Line</option>
                <option value="scatter">Scatter</option>
                <option value="pie">Pie</option>
              </select>
            </label>
          </div>

          {/* Dropdown for selecting X-axis variable */}
          <div style={{ marginBottom: '10px' }}>
            <label>
              X-axis:
              <select value={xAxis} onChange={handleXAxisChange} style={{ marginLeft: '10px' }}>
                {columnNames.map((col) => (
                  <option key={col} value={col}>
                    {col}
                  </option>
                ))}
              </select>
            </label>
          </div>

          {/* Dropdown for selecting Y-axis variable */}
          <div style={{ marginBottom: '10px' }}>
            <label>
              Y-axis:
              <select value={yAxis} onChange={handleYAxisChange} style={{ marginLeft: '10px' }}>
                {columnNames.map((col) => (
                  <option key={col} value={col}>
                    {col}
                  </option>
                ))}
              </select>
            </label>
          </div>

          {/* Dropdown for selecting aggregation type */}
          <div style={{ marginBottom: '10px' }}>
            <label>
              Aggregation:
              <select value={aggregation} onChange={handleAggregationChange} style={{ marginLeft: '10px' }}>
                <option value="Sum">Sum</option>
                <option value="Count">Count</option>
                <option value="Average">Average</option>
              </select>
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateChart}>
            Create Graph
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Render the Chart after the user has created it */}
      {showChart && (
        <div style={{ marginTop: '20px' }}>
          <Chart options={chartData.options} series={chartData.series} type={chartType} height={350} />
        </div>
      )}
    </div>
  );
};

// export default DynamicDashboard;
