import React,{useState, useContext, useEffect, useMemo} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister,faChartLine, faCloudUploadAlt,faUsers,faTable , faDatabase,faUser,faCity , faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
// import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import {Form ,  Breadcrumb,Button,Col, Row , Nav, Card, Image, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';

import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import Chart  from 'react-apexcharts';
import { CreateChart } from "../../components/CreateChart"; 


import { useAuth } from '../AuthContext';

export default () => {
  const history = useHistory()
  const [selectedForm,setSelectedForm] = useState('')
  // const [lead,setLead] =useState('')
  const [listLead,setListlead] =useState([])
  const [showModal, setShowModal] = useState(false);

  const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  
  const userCon  =  JSON.parse(localStorage.getItem("user"))
  const [ leadId, setLeadId] = useState("")
  const [ selectedUser, setSelectedUser] = useState("")
  const [ allFormsData, setAllFormsData ] = useState([])

  const [dailyOptions, setDailyOptions] = useState([]);
  const [dailySeries, setDailySeries] = useState([]);
  const [formsLead, setFormsLead] = useState([])
  const [activeButton, setActiveButton] = useState('daily');
  const { token } = useAuth();
  const [user,setUser] =useState([])

   
  const [listLeadName, setListLeadName] = useState([]);
  const [listLeadNb, setListLeadNb] = useState([]);


  const [listFormsName, setListFormsName] = useState([]);
  const [listFormsNb, setListFormsNb] = useState([]);

  const [listForms,setListForms] =useState([])

  const [chartData,setChartData] =useState([])

  const handleCloseModal = () => setShowModal(false)


  const fetchChartData = () => {
    if(selectedForm != "") {
      fetch(`/api/fetchChartData/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({userId :userCon._id,formId:selectedForm})
      }).then(response => response.json())
        .then(
        data => {
          setChartData(data['chart'])
        }
      );
    }
    
  }

  const handleCreateChart = (newChart) => {
        fetchChartData()
    
    };

    useEffect(() => {
      fetchListForms()
      // fetchNbteam();
      fetchLead()
    }, []);
  
  
    
  // function fetchNbteam(){

  //   fetch(`/api/getNbteam/${userCon._id}`).then(
  //     response => response.json()
  //   ).then(
  //       data => {
  //         setNbTeam(data['nb'])
  //       })
  // }

  const fetchListForms= () => {
    fetch(`/api/getListForms/${userCon._id}`).then(
    response => response.json()
).then(
    data => setListForms(data["forms"])
)
};


  
  
  

    const  fetchFormsResponse = (leadId,sDate,eDate) => {
      if(selectedForm != "" ) {
        fetch(`/api/getFormsDataResponse`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({userId :userCon._id,formId:selectedForm, userSelecteId:selectedUser, leadId : leadId ,dateStart : sDate, datEnd : eDate}),
        }).then(
          response => response.json()
        ).then(
          data => {
           
            if(data.status === "success") {
              // console.log('forms data'+JSON.stringify(data) )
              setAllFormsData(data['chartData'])

            }else if(data.status === "session_expired") {
             
              history.push("/sign-in");
            }else {
              swal({
                title: "Erreur",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
            }
            
          }
        )
      }
      
    }



const fetchUsers = (leadId) => {
  fetch('/api/getUsersLead', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({userId :userCon._id,leadId : leadId}),
     }).then(
      response => response.json()
    ).then(data => setUser(data["users"]))

}
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  
const fetchLead = () => {
  fetch(`/api/getLead/${userCon._id}`).then(
      response => response.json()
  ).then(
      data =>   setListlead(data["lead"])
  )
}


  useEffect(() => {
    fetchFormsResponse(leadId,startDate,endDate);
  }, [selectedForm, selectedUser, leadId,startDate,endDate]);




  useEffect(() => {
    fetchChartData()
  }, [selectedForm]);


  



  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartDate = (e) => {
    console.log('start date');
    setStartDate(e.target.value);
  };

  const handleForms = (e) =>{
    setSelectedForm(e.target.value)
}

const handleUser = (e) =>{
  setSelectedUser(e.target.value)
}

const handleLead = (e) =>{
  // console.log('my lead '+ e.target.value)
  setSelectedUser('')
  fetchUsers(e.target.value)
  setLeadId(e.target.value)
}

const averageValuesByDate = (data) => {
  const result = data.reduce((acc, obj) => {
    const [date, value] = Object.entries(obj)[0]; // Extraire la clé (date) et la valeur
    const day = new Date(date).toISOString().split('T')[0]; // Obtenir seulement la date (AAAA-MM-JJ)
    if (!acc[day]) {
      acc[day] = { total: 0, count: 0 }; // Initialiser les totaux et le compteur
    }
    acc[day].total += parseInt(value, 10); // Ajouter la valeur au total de la date
    acc[day].count += 1; // Incrémenter le compteur
    return acc;
  }, {});

  // Calculer les moyennes à partir des totaux et des compteurs
  return Object.fromEntries(
    Object.entries(result).map(([date, { total, count }]) => [date, total / count])
  );
}


const  sumValuesByDay = (data) => {
  const dailySums = {};

  data.forEach(item => {
      const [timestamp, value] = Object.entries(item)[0];

      // Convertir le timestamp en début de journée au format ISO
      const date = new Date(timestamp);
      const isoDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())).toISOString();

      // Ajouter la valeur à la somme pour cette date
      dailySums[isoDate] = (dailySums[isoDate] || 0) + parseInt(value, 10);
  });

  // Extraire les dates et les sommes dans deux tableaux distincts
  const dates = Object.keys(dailySums);
  const sums = Object.values(dailySums);

  return { dates, sums };
}

const medianValuesByDate = (data) => {
  const grouped = data.reduce((acc, obj) => {
    const [date, value] = Object.entries(obj)[0]; // Extraire la clé (date) et la valeur
    const day = new Date(date).toISOString().split('T')[0]; // Obtenir seulement la date (AAAA-MM-JJ)
    if (!acc[day]) {
      acc[day] = []; // Initialiser un tableau pour stocker les valeurs
    }
    acc[day].push(parseInt(value, 10)); // Ajouter la valeur au tableau de la date
    return acc;
  }, {});

  // Calculer la médiane pour chaque groupe de date
  return Object.fromEntries(
    Object.entries(grouped).map(([date, values]) => {
      values.sort((a, b) => a - b); // Trier les valeurs
      const mid = Math.floor(values.length / 2); // Trouver l'index central
      const median =
        values.length % 2 === 0
          ? (values[mid - 1] + values[mid]) / 2 // Moyenne des deux valeurs centrales si pair
          : values[mid]; // Valeur centrale si impair
      return [date, median];
    })
  );
}

const sumByFormattedDate = (data) => {
  const result = {};

  data.forEach((item) => {
  // Récupérer la première paire clé-valeur de l'objet
  const [dateISO, value] = Object.entries(data)[0];
  console.log("dateISO",dateISO);

  // Convertir la date ISO en objet Date
  const date = new Date(dateISO);

  // Formater la date en dd/MM/yy HH:mm
  const formattedDate = `${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(2, "0")}/${String(date.getFullYear()).slice(-2)} ${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;

  // Additionner les valeurs selon la date formatée
  if (!result[formattedDate]) {
    result[formattedDate] = 0;
  }
  result[formattedDate] += value;
  });

  return result;
}


const countValuesByDate = (data) => {
  const result = {};

  data.forEach(item => {
    // Extraire la date ISO et la valeur de l'objet
    const [dateISO, value] = Object.entries(item)[0];
    
    // Convertir la date ISO en objet Date
    const date = new Date(dateISO);
    
    // Formater la date en dd/MM/yy
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getFullYear()).slice(-2)}`;
    
    // Initialiser le compteur pour le jour s'il n'existe pas
    if (!result[formattedDate]) {
      result[formattedDate] = {};
    }
    
    // Initialiser le compteur pour la valeur s'il n'existe pas
    if (!result[formattedDate][value]) {
      result[formattedDate][value] = 0;
    }
    
    // Incrémenter le compteur
    result[formattedDate][value]++;
  });

  return result;
}


const calculateDailyAverages = (data) => {
  const groupedData = {};
  const uniqueValues = new Set();

  // Grouper les données par date et collecter les valeurs uniques
  data.forEach(item => {
    const [dateISO, value] = Object.entries(item)[0];
    const date = new Date(dateISO);
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getFullYear()).slice(-2)}`;

    // Initialiser le groupe pour cette date si nécessaire
    if (!groupedData[formattedDate]) {
      groupedData[formattedDate] = {};
    }

    // Initialiser la liste pour cette valeur si nécessaire
    if (!groupedData[formattedDate][value]) {
      groupedData[formattedDate][value] = [];
    }

    // Ajouter l'occurrence à la liste
    groupedData[formattedDate][value].push(1); // On utilise "1" pour compter les occurrences
    uniqueValues.add(value);
  });

  // Construire les clés (dates) triées
  const keys = Object.keys(groupedData).sort();

  // Construire les valeurs en calculant les moyennes
  const values = Array.from(uniqueValues).map(name => {
    const data = keys.map(date => {
      const occurrences = groupedData[date][name] || [];
      const sum = occurrences.reduce((acc, curr) => acc + curr, 0);
      return occurrences.length > 0 ? sum / occurrences.length : 0; // Calculer la moyenne
    });
    return { name, data };
  });

  return { keys, values };
}


const calculateDailyMedians = (data) => {
  const groupedData = {};
  const uniqueValues = new Set();

  // Grouper les données par date et collecter les valeurs uniques
  data.forEach(item => {
    const [dateISO, value] = Object.entries(item)[0];
    const date = new Date(dateISO);
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getFullYear()).slice(-2)}`;

    // Initialiser le groupe pour cette date si nécessaire
    if (!groupedData[formattedDate]) {
      groupedData[formattedDate] = {};
    }

    // Initialiser la liste pour cette valeur si nécessaire
    if (!groupedData[formattedDate][value]) {
      groupedData[formattedDate][value] = [];
    }

    // Ajouter un indicateur pour chaque occurrence (on ajoute `1`)
    groupedData[formattedDate][value].push(1);
    uniqueValues.add(value);
  });

  // Fonction pour calculer la médiane
  const  calculateMedian = (values) => {
    if (values.length === 0) return 0;
    const sorted = values.sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    return sorted.length % 2 === 0
      ? (sorted[mid - 1] + sorted[mid]) / 2
      : sorted[mid];
  }

  // Construire les clés (dates) triées
  const keys = Object.keys(groupedData).sort();

  // Construire les valeurs en calculant les médianes
  const values = Array.from(uniqueValues).map(name => {
    const data = keys.map(date => {
      const occurrences = groupedData[date][name] || [];
      return calculateMedian(occurrences);
    });
    return { name, data };
  });

  return { keys, values };
}


const groupAndCountValuesByDate = (data) => {
  const groupedData = {};
  const uniqueValues = new Set();

  // Étape 1 : Grouper par date et compter les valeurs
  data.forEach(item => {
    const [dateISO, value] = Object.entries(item)[0];
    const date = new Date(dateISO);

    // Formater la date en format ISO (YYYY-MM-DD)
    const isoDate = date.toISOString().split('T')[0];

    // Initialiser le compteur pour la date s'il n'existe pas
    if (!groupedData[isoDate]) {
      groupedData[isoDate] = {};
    }

    // Initialiser le compteur pour la valeur s'il n'existe pas
    if (!groupedData[isoDate][value]) {
      groupedData[isoDate][value] = 0;
    }

    // Incrémenter le compteur
    groupedData[isoDate][value]++;
    
    // Ajouter la valeur à l'ensemble des valeurs uniques
    uniqueValues.add(value);
  });

  // Étape 2 : Construire l'objet de résultat
  const keys = Object.keys(groupedData).sort(); // Trier les dates au format ISO
  const values = Array.from(uniqueValues).map(name => {
    const data = keys.map(date => groupedData[date][name] || 0);
    return { name, data };
  });

  return { keys, values };
}



const  convertArrayToISO = (datesArray) => {
  return datesArray.map(dateString => {
    // Extraire les parties de la date et de l'heure
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);

    // Construire l'objet Date
    const fullYear = 2000 + year; // Pour convertir l'année sur 2 chiffres en année complète (24 -> 2024)
    const date = new Date(Date.UTC(fullYear, month - 1, day, hours, minutes));

    // Retourner la date en format ISO
    return date.toISOString();
  });
}


const sumValuesByCategory = (A, B) => {
  const groupedData = {};
  const uniqueCategories = new Set();

  A.forEach((item, index) => {
    const [dateISO, category] = Object.entries(item)[0];
    const date = new Date(dateISO);

    // Formatter la date en `YYYY-MM-DD`
    const formattedDate = date.toISOString().split('T')[0];

    // Initialiser la structure pour cette date si nécessaire
    if (!groupedData[formattedDate]) {
      groupedData[formattedDate] = {};
    }

    // Initialiser la catégorie si nécessaire
    if (!groupedData[formattedDate][category]) {
      groupedData[formattedDate][category] = 0;
    }

    // Ajouter la valeur correspondante de B
    groupedData[formattedDate][category] += Number(B[index]) || 0;

    // Stocker les catégories uniques
    uniqueCategories.add(category);
  });

  // Obtenir les dates triées
  const keys = Object.keys(groupedData).sort();

  // Construire les valeurs
  const values = Array.from(uniqueCategories).map((category) => {
    const data = keys.map((date) => groupedData[date][category] || 0);
    return { name: category, data };
  });

  return { keys, values };
}

const medianValuesByCategory = (A, B) => {
  const groupedData = {};
  const uniqueCategories = new Set();

  A.forEach((item, index) => {
    const [dateISO, category] = Object.entries(item)[0];
    const date = new Date(dateISO);

    // Formatter la date en `YYYY-MM-DD`
    const formattedDate = date.toISOString().split('T')[0];

    // Initialiser la structure pour cette date si nécessaire
    if (!groupedData[formattedDate]) {
      groupedData[formattedDate] = {};
    }

    // Initialiser la catégorie si nécessaire
    if (!groupedData[formattedDate][category]) {
      groupedData[formattedDate][category] = [];
    }

    // Ajouter la valeur correspondante de B
    let value = Number(B[index]) || 0;
    groupedData[formattedDate][category].push(value);

    // Stocker les catégories uniques
    uniqueCategories.add(category);
  });

  // Fonction pour calculer la médiane
  const calculateMedian = (values) => {
    if (values.length === 0) return 0;
    values.sort((a, b) => a - b);
    const middle = Math.floor(values.length / 2);
    if (values.length % 2 === 0) {
      return (values[middle - 1] + values[middle]) / 2;
    }
    return values[middle];
  }

  // Obtenir les dates triées
  const keys = Object.keys(groupedData).sort();

  // Construire les valeurs avec les médianes
  const values = Array.from(uniqueCategories).map((category) => {
    const data = keys.map((date) => {
      if (groupedData[date][category]) {
        return calculateMedian(groupedData[date][category]);
      }
      return 0; // Si la catégorie n'existe pas pour cette date
    });
    return { name: category, data };
  });

  return { keys, values };
}


const averageValuesByCategory = (A, B) => {
  const groupedData = {};
  const uniqueCategories = new Set();

  A.forEach((item, index) => {
    const [dateISO, category] = Object.entries(item)[0];
    const date = new Date(dateISO);

    // Formatter la date en `YYYY-MM-DD`
    const formattedDate = date.toISOString().split('T')[0];

    // Initialiser la structure pour cette date si nécessaire
    if (!groupedData[formattedDate]) {
      groupedData[formattedDate] = {};
    }

    // Initialiser la catégorie si nécessaire
    if (!groupedData[formattedDate][category]) {
      groupedData[formattedDate][category] = { sum: 0, count: 0 };
    }

    // Ajouter la valeur correspondante de B et incrémenter le compteur
    groupedData[formattedDate][category].sum += Number(B[index]) || 0;
    groupedData[formattedDate][category].count += 1;

    // Stocker les catégories uniques
    uniqueCategories.add(category);
  });

  // Obtenir les dates triées
  const keys = Object.keys(groupedData).sort();

  // Construire les valeurs avec les moyennes
  const values = Array.from(uniqueCategories).map((category) => {
    const data = keys.map((date) => {
      if (groupedData[date][category]) {
        const { sum, count } = groupedData[date][category];
        return count > 0 ? sum / count : 0; // Calcul de la moyenne
      }
      return 0; // Si la catégorie n'existe pas pour cette date
    });
    return { name: category, data };
  });

  return { keys, values };
}



  const LineChart = ({ chart }) => {
    console.log("chart", chart)
    let field = chart.fields[0]

    let fieldValue = chart.fieldValue.length > 0 ? chart.fieldValue[0] : null
    let rowData = []
    let rowDataValue = []

      for(let i=0;i<allFormsData.length;i++) {
        let data = JSON.parse(allFormsData[i].response)
        
        let endDate = allFormsData[i].endDate
        let value = data[field] 
        rowData.push( {
          [endDate]:value
        })

        let rowValue = fieldValue != null ? data[fieldValue] : null
        rowDataValue.push( 
          rowValue
        )
      }
      console.log("rowData", rowData)
      console.log("rowDataValue", rowDataValue)
      let chartData = []
      let chartKeys = []
      let chartSeries = []

      let quantitativeField = ["NumberInput"]
      let qualitativeField = ["Dropdown", "RadioButtons", "Rating", "Range"]
      
      

      if(chart.operation == "sum") {
        if(quantitativeField.includes(chart.fieldType[0])) {
          chartData = sumValuesByDay(rowData)
          chartKeys = chartData.dates
          chartSeries = [{
            name:chart.name + " - Somme ",
            data: chartData.sums
          }]
        }else if(qualitativeField.includes(chart.fieldType[0])) {
          chartData = sumValuesByCategory(rowData, rowDataValue)
          chartKeys = chartData.keys
          chartSeries = chartData.values
        }
        
      } else if(chart.operation == "average") {
        if(quantitativeField.includes(chart.fieldType[0])) {
          
          chartData = averageValuesByDate(rowData)
          chartKeys = Object.keys(chartData)
          chartSeries = [{
            name:chart.name + " - Moyenne ",
            data: Object.values(chartData)
          }]
        }else if(qualitativeField.includes(chart.fieldType[0])) {
          chartData = averageValuesByCategory(rowData, rowDataValue)
          chartKeys = chartData.keys
          chartSeries = chartData.values
        }
        

      }else if(chart.operation == "median") {
        if(quantitativeField.includes(chart.fieldType[0])) {
          
          chartData = medianValuesByDate(rowData)
            chartKeys = Object.keys(chartData)
            chartSeries = [{
              name:chart.name + " - Mediane ",
              data: Object.values(chartData)
            }]
        }else if(qualitativeField.includes(chart.fieldType[0])) {
          chartData = medianValuesByCategory(rowData, rowDataValue)
          chartKeys = chartData.keys
          chartSeries = chartData.values
        }

        
      }else {
        chartData = sumValuesByDay(rowData)
        chartKeys = chartData.dates
        chartSeries = [{
          name:chart.name + " - Somme ",
          data: chartData.sums
        }]
      }

      console.log("chartData-------", chartData)

   
    const chartOptions = {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: chartKeys
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    };

    


    return (
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="area"
        height="350"
      />
    );
  };




  

  const ScatterChart = ({ chart }) => {
    

    const _series = [{
      name: "SAMPLE A",
      data: [
      [16.4, 5.4], [21.7, 2], [25.4, 3], [19, 2], [10.9, 1], [13.6, 3.2], [10.9, 7.4], [10.9, 0], [10.9, 8.2], [16.4, 0], [16.4, 1.8], [13.6, 0.3], [13.6, 0], [29.9, 0], [27.1, 2.3], [16.4, 0], [13.6, 3.7], [10.9, 5.2], [16.4, 6.5], [10.9, 0], [24.5, 7.1], [10.9, 0], [8.1, 4.7], [19, 0], [21.7, 1.8], [27.1, 0], [24.5, 0], [27.1, 0], [29.9, 1.5], [27.1, 0.8], [22.1, 2]]
    },{
      name: "SAMPLE B",
      data: [
      [36.4, 13.4], [1.7, 11], [5.4, 8], [9, 17], [1.9, 4], [3.6, 12.2], [1.9, 14.4], [1.9, 9], [1.9, 13.2], [1.4, 7], [6.4, 8.8], [3.6, 4.3], [1.6, 10], [9.9, 2], [7.1, 15], [1.4, 0], [3.6, 13.7], [1.9, 15.2], [6.4, 16.5], [0.9, 10], [4.5, 17.1], [10.9, 10], [0.1, 14.7], [9, 10], [12.7, 11.8], [2.1, 10], [2.5, 10], [27.1, 10], [2.9, 11.5], [7.1, 10.8], [2.1, 12]]
    },{
      name: "SAMPLE C",
      data: [
      [21.7, 3], [23.6, 3.5], [24.6, 3], [29.9, 3], [21.7, 20], [23, 2], [10.9, 3], [28, 4], [27.1, 0.3], [16.4, 4], [13.6, 0], [19, 5], [22.4, 3], [24.5, 3], [32.6, 3], [27.1, 4], [29.6, 6], [31.6, 8], [21.6, 5], [20.9, 4], [22.4, 0], [32.6, 10.3], [29.7, 20.8], [24.5, 0.8], [21.4, 0], [21.7, 6.9], [28.6, 7.7], [15.4, 0], [18.1, 0], [33.4, 0], [16.4, 0]]
    }];
  
    var _options = {
      chart: {
        height: 350,
        type: 'scatter',
        zoom: {
          enabled: true,
          type: 'xy'
        }
      },
      xaxis: {
        tickAmount: 10,
        labels: {
          formatter: function(val) {
            return parseFloat(val).toFixed(1)
          }
        }
      },
      yaxis: {
        tickAmount: 7
      }
    };
  
    return  <Chart
                options={_options}
                series={_series}
                type="scatter"
                height="350"
              />    
  };

  const computeOccur = (arr) => {
    return arr.reduce((acc, valeur) => {
      acc[valeur] = (acc[valeur] || 0) + 1;
      return acc;
    }, {});
  };

  const sumByKey = (tableau) => {
    const resultats = {};
  
    tableau.forEach(obj => {
      Object.keys(obj).forEach(cle => {
        const valeur = parseFloat(obj[cle]) || 0; 
        resultats[cle] = (resultats[cle] || 0) + valeur; 
      });
    });
  
    return resultats;
  }

  const sumCategValuesByDate = (data) => {
    const groupedData = {};
  
    data.forEach(item => {
      const [dateISO, value] = Object.entries(item)[0];
      const date = new Date(dateISO);
  
      // Formatter la date en `YYYY-MM-DD` pour regrouper les jours
      const formattedDate = date.toISOString().split('T')[0];
  
      // Ajouter la valeur au groupe correspondant
      if (!groupedData[formattedDate]) {
        groupedData[formattedDate] = 0;
      }
  
      groupedData[formattedDate] += Number(value);
    });
  
    return groupedData;
  }

  const averageByKey = (tableau) => {
    const sommes = {};
    const occurrences = {};
  
    tableau.forEach(obj => {
      Object.keys(obj).forEach(cle => {
        const valeur = parseFloat(obj[cle]) || 0; // Convertir en nombre, 0 par défaut si invalide
        sommes[cle] = (sommes[cle] || 0) + valeur; // Ajouter à la somme existante ou initialiser
        occurrences[cle] = (occurrences[cle] || 0) + 1; // Compter les occurrences
      });
    });
  
    // Calculer la moyenne pour chaque clé
    const moyennes = {};
    Object.keys(sommes).forEach(cle => {
      moyennes[cle] = sommes[cle] / occurrences[cle];
    });
  
    return moyennes;
  }
   
  const medianeByKey = (tableau) => {
    const valeursParCle = {};
  
    // Regrouper les valeurs par clé
    tableau.forEach(obj => {
      Object.keys(obj).forEach(cle => {
        const valeur = parseFloat(obj[cle]) || 0; // Convertir en nombre, 0 par défaut si invalide
        if (!valeursParCle[cle]) {
          valeursParCle[cle] = [];
        }
        valeursParCle[cle].push(valeur);
      });
    });
  
    // Calculer la médiane pour chaque clé
    const medians = {};
    Object.keys(valeursParCle).forEach(cle => {
      const valeurs = valeursParCle[cle].sort((a, b) => a - b); // Trier les valeurs
      const milieu = Math.floor(valeurs.length / 2);
  
      if (valeurs.length % 2 === 0) {
        // Si nombre pair, médiane = moyenne des deux valeurs centrales
        medians[cle] = (valeurs[milieu - 1] + valeurs[milieu]) / 2;
      } else {
        // Si nombre impair, médiane = valeur centrale
        medians[cle] = valeurs[milieu];
      }
    });
  
    return medians;
  }
  
  const PieChart = ({ chart }) => {
    console.log("chart", chart)
    let field = chart.fields[0]
    let fieldValue = chart.fieldValue.length > 0 ? chart.fieldValue[0] : null
    let rowData = []
    let rowDataValue = []
    for(let i=0;i<allFormsData.length;i++) {
      let data = JSON.parse(allFormsData[i].response)
      let value = fieldValue != null ? data[fieldValue] : null
      rowDataValue.push( {
        [data[field]]:value
      })
      rowData.push(data[field])
      
    }
    let chartData = []
    if(chart.operation == "count") {
      chartData = computeOccur(rowData); 
    } else if(chart.operation == "sum") {
      if(fieldValue != null) {
        chartData = sumByKey(rowDataValue); 
      }else {
        chartData = computeOccur(rowData); 
      } 
    } else if(chart.operation == "average") {
      if(fieldValue != null) {
        chartData = averageByKey(rowDataValue); 
      }else {
        chartData = computeOccur(rowData); 
      } 
    }else if(chart.operation == "median") {
      if(fieldValue != null) {
        chartData = medianeByKey(rowDataValue); 
      }else {
        chartData = computeOccur(rowData); 
      } 
    } else {
      chartData = computeOccur(rowData); 
    }

      
    

    let _series = Object.values(chartData)
  
    var _options = {
      chart: {
      height: 350,
      type: 'pie',
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      }
    },
    labels: Object.keys(chartData),
    };
  
    return  <Chart
                options={_options}
                series={_series}
                type="pie"
                height="350"
              />    
  };

  const ColumnChart = ({ chart }) => {

    let field = chart.fields[0]
    let fieldValue = chart.fieldValue.length > 0 ? chart.fieldValue[0] : null
    let rowData = []
    let rowDataValue = []
    for(let i=0;i<allFormsData.length;i++) {
      let data = JSON.parse(allFormsData[i].response)
      let value = fieldValue != null ? data[fieldValue] : null
      rowDataValue.push( {
        [data[field]]:value
      })
      rowData.push(data[field])
      
    }
    let chartData = []
    if(chart.operation == "count") {
      chartData = computeOccur(rowData); 
    } else if(chart.operation == "sum") {
      if(fieldValue != null) {
        chartData = sumByKey(rowDataValue); 
      }else {
        chartData = computeOccur(rowData); 
      } 
    } else if(chart.operation == "average") {
      if(fieldValue != null) {
        chartData = averageByKey(rowDataValue); 
      }else {
        chartData = computeOccur(rowData); 
      } 
    }else if(chart.operation == "median") {
      if(fieldValue != null) {
        chartData = medianeByKey(rowDataValue); 
      }else {
        chartData = computeOccur(rowData); 
      } 
    } else {
      chartData = computeOccur(rowData); 
    }
    
    var series = [{
      data: Object.values(chartData)
    }]

    var options = {
      chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      }
      },
      // colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: true
      },
      xaxis: {
        categories: Object.keys(chartData),
        labels: {
          style: {
            // colors: colors,
            fontSize: '12px'
          }
        }
      }
    };

  
    return  <Chart
                options={options}
                series={series}
                type="bar"
                height="350"
              />  
  };

  const handleCreateChartModal = () => {
    if(selectedForm != '' ) {
      setShowModal(true);
    }else {
      swal({
        title: "Info",
        text: "Veuillez séléctionner un Projet.",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
      .then((result) => {
        console.log(result)
      });
    }
  }

  const getChartContent = (chart) => {
    switch(chart.type) {
      case 'column':
        return <ColumnChart chart={chart} />;
      case 'line':
          return <LineChart chart={chart} />;
      case 'pie':
        return <PieChart chart={chart}  />;
      case 'scatter':
        return <ScatterChart chart={chart} />;
      default:
        return;
    }
    
  };


  // const ScatterChartProject = useMemo(() => {
  //   return <ScatterProjectchart series={listFormsNb} options={listFormsName} />;
  // }, [listFormsNb, listFormsName]);

  // const chartTopProject = useMemo(() => {
  //   return <Projectchart series={listFormsNb} options={listFormsName} />;
  // }, [listFormsNb, listFormsName]);
  
  // const chartLead = useMemo(() => {
  //   return <Leadchart series={listLeadNb} options={listLeadName} />;
  // }, [listLeadNb, listLeadName]);
  



  // console.log('forms location'+ JSON.stringify(formsLocation.forms.length) )
  return (
    <>
  <Row>
    {/* Inputs Section */}
    <Form className="mt-4 p-3 shadow-lg bg-light rounded">
      <Row>
        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group> 
            <Form.Label><i className="fas fa-user-plus"></i> Responsable</Form.Label>
            <Form.Select  onChange={handleLead} className="shadow-sm dashboard-select">
              <option value=""></option> 
              {listLead &&
                              listLead.map(l => <option value={l._id}>{l.firstName}</option> )
                          }
            </Form.Select>
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}>
          <Form.Group>
            <Form.Label><i className="fas fa-project-diagram"></i> Nom du projet</Form.Label>
            <Form.Select onChange={handleForms} className="shadow-sm dashboard-select">
              <option value=""></option> 
              {listForms &&
                                listForms.map(l => <option value={l._id}>{l.name}</option> )
                            }
            </Form.Select>
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group>
            <Form.Label><i className="fas fa-user"></i> Nom d'utilisateur</Form.Label>
            <Form.Select onChange={handleUser}   className="shadow-sm dashboard-select">
              <option value=""></option> 
                  {user &&
                    user.map(l => <option value={l._id}>{l.firstName}</option> )
                  }
            </Form.Select>
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group>
            <Form.Label><i className="fas fa-calendar-alt"></i> Date de début :</Form.Label>
            <Form.Control 
              type="date" 
              value={startDate} 
              onChange={handleStartDate} 
              className="shadow-sm dashboard-select"
            />
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group>
            <Form.Label><i className="fas fa-calendar-alt"></i> Date de fin :</Form.Label>
            <Form.Control 
              type="date" 
              value={endDate} 
              onChange={handleEndDate} 
              className="shadow-sm dashboard-select"
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  </Row>
                  

  
  {/* Main Content: Left (Boxes and Graphs) and Right (Map) */}
  <Row className="mt-0">
    {/* Left side: Boxes and Graphs */}
    <Col xs={12} xl={12}>
      {/* 4 Counter Boxes */}

      <div className="d-flex justify-content-between  flex-md-nowrap align-items-center py-2">
            <div className="d-block  ">
            
            {/* <h4>Formulaires</h4>
            <p className="mb-0">Créer votre formulaire selon votre besoin.</p> */}
          </div>
          <div className="btn-toolbar mb-md-0" >
          
              <ButtonGroup>
                {/* <Button variant="outline-primary" size="sm">Remplir</Button> */}
                <Button variant="primary" size="sm" onClick={() => handleCreateChartModal()}><FontAwesomeIcon icon={faPlus} className="me-2" />Créer un graphique</Button>
              </ButtonGroup>
           
            
          </div>
        </div>

      {/* Dynamic Graphs Section */}
      <Row>
        {chartData.length == 0 ? (
             <div className="d-block  " style={{textAlign:"center"}}>
            
             <h4>Aucun graphique créé.</h4>
           </div>
        ) : (
          chartData.map((chart, index) => {
            return (
              <Col xs={12} sm={5} className="mb-4">
                  <Card border="light" className="shadow-sm">
                      <Card.Header className="border-bottom border-light d-flex justify-content-between dashboard-table">
                        <h5 className="mb-0">{chart.name}</h5>
                      
                      </Card.Header>                
                    {getChartContent(chart)}

                  </Card>
                </Col>
              )
          })
        )
          
        }
          
      </Row>


      
    </Col>

    {/* Right side: Map */}
    
    </Row>
    <CreateChart show={showModal} handleClose = {handleCloseModal} selectedForm={selectedForm} onCreateChart ={handleCreateChart}  />
    </>
  );
};
