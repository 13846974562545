import React, {useState,useEffect} from "react";
import {Modal ,Form ,Button,Col, Row,Toast  } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert';
import { useAuth } from '../pages/AuthContext';
import ScaleLoader from "react-spinners/ScaleLoader";



export const CreateChart = ({ show, handleClose,selectedForm, onCreateChart }) => {
  const history = useHistory()
  const { token } = useAuth();
  let [loading, setLoading] = useState(false);
  let [loadingValue, setLoadingValue] = useState(false);
  let [saving, setSaving] = useState(false);
  const [chartName, setChartName] = useState('');
  const [chartType, setChartType] = useState('');
  const [chartOperation, setChartOperation] = useState('');
  const [chartFields, setChartFields] = useState([]);
  const [chartFieldsType, setChartFieldsType] = useState([]);
  const [chartFieldsValue, setChartFieldsValue] = useState([]);
  const [fieldNameOptions,setFieldNameOptions] = useState([]);
  const [fieldValueOptions,setFieldValueOptions] = useState([]);
  const [user, setUser] = useState([]);

  console.log(chartName, chartType, chartFields, chartOperation, chartFieldsValue)

  const getFieldsValueForChart = () => {
    let userCon =  localStorage.getItem("user")

    if(userCon !== null) {
      
      if(selectedForm != null && selectedForm != ""){
        setLoadingValue(true)
        userCon =  JSON.parse(userCon)
        fetch(`/api/getFieldsValueForChart/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({userId :userCon._id,formId:selectedForm})
        }).then(response => response.json())
          .then(
          data => {
            setLoadingValue(false)
            if(data.status === "success") {
              setFieldValueOptions( data["fields"])
            }else if(data.status === "session_expired") {
              localStorage.removeItem('accessToken')
              localStorage.removeItem('user')
              swal({
                title: "Session expirée",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
              history.push("/sign-in");
            }else {
              swal({
                title: "Erreur",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
            }
          }
        );
      }
      
    }else {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
      swal({
        title: "Session expirée",
        text: "Votre session a expirée.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((result) => {
        console.log(result)
      });
      history.push("/sign-in");
    }
  }

  const getFieldsForChart = () => {
    
    let userCon =  localStorage.getItem("user")

    if(userCon !== null) {
      console.log("tonga ato: ", selectedForm)
      
      if(selectedForm != null && selectedForm != ""){
        setLoading(true)
        userCon =  JSON.parse(userCon)
        fetch(`/api/getFieldsForChart/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({userId :userCon._id,formId:selectedForm,chartType})
        }).then(response => response.json())
          .then(
          data => {
            setLoading(false)
            if(data.status === "success") {
              // console.log('all forms '+data)
              let fields_options = data["fields"].map((option) => ({label: option.label, value: option.id, element:option.element}));
              setFieldNameOptions(fields_options)
            }else if(data.status === "session_expired") {
              localStorage.removeItem('accessToken')
              localStorage.removeItem('user')
              swal({
                title: "Session expirée",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
              history.push("/sign-in");
            }else {
              swal({
                title: "Erreur",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
            }
          }
        );
      }
      
    }else {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
      swal({
        title: "Session expirée",
        text: "Votre session a expirée.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((result) => {
        console.log(result)
      });
      history.push("/sign-in");
    }
  }

  useEffect(() => {
    
    getFieldsValueForChart()
  }, [chartOperation]);

  useEffect(() => {
    
    getFieldsForChart()
  }, [chartType]);
  

  const chartTypeOptions = [
    {
      label: "Histogramme",
      value: "column"
    },
    {
      label: "Ligne",
      value: "line"
    },
    {
      label: "Circulaire",
      value: "pie"
    },
    // {
    //   label: "Nuage de points",
    //   value: "scatter"
    // }
  ];

  const chartOperations = [
    {
      label: "Somme",
      value: "sum"
    },
    {
      label: "Moyenne",
      value: "average"
    },
    {
      label: "Mediane",
      value: "median"
    }
  ];

  const chartOperationsWithCount = [
    {
      label: "Compter",
      value: "count"
    },
    {
      label: "Somme",
      value: "sum"
    },
    {
      label: "Moyenne",
      value: "average"
    },
    {
      label: "Mediane",
      value: "median"
    }
  ];


    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setChartName(value);
    }

    const handleChangeType = (selectedOption) => {
      let selectedValue;
      let name = "type"
      if(selectedOption === null ){
        selectedValues = ''
      }else{
         selectedValue = selectedOption.value
      }
      
      setChartType(selectedValue);
    };

    const handleChangeFieldValue = (selectedOption) => {

      let selectedValue;
      let name = "fields"
      if(selectedOption === null ){
        selectedValues = ''
      }else{
         selectedValue = selectedOption.id
      }
      
      setChartFieldsValue([selectedValue]);
    };


    const handleChangeField = (selectedOption) => {

      let selectedValue;
      let selectedValueType;
      let name = "fields"
      if(selectedOption === null ){
        selectedValues = ''
      }else{
         selectedValue = selectedOption.value
         selectedValueType = selectedOption.element
      }
      

      setChartFields([selectedValue]);
      setChartFieldsType([selectedValueType])
    };

    const handleChangeOperation = (selectedOption) => {
      let selectedValue;
      if(selectedOption === null ){
        selectedValues = ''
      }else{
         selectedValue = selectedOption.value
      }
      
      setChartOperation(selectedValue);
    };

    const saveNewChart = () => {
      let userCon =  localStorage.getItem("user")

      if(userCon !== null) {
        
        if(selectedForm != null && selectedForm != ""){
          setSaving(true)
          userCon =  JSON.parse(userCon)
          fetch(`/api/saveNewChart/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({chartName, chartType, chartFields, userId :userCon._id,formId:selectedForm, chartOperation, chartFieldsValue})
          }).then(response => response.json())
            .then(
            data => {
              setSaving(false)
              if(data.status === "success") {
                // console.log('all forms '+data)
                setChartName('')
                setChartType('')
                setChartFields([])
                setChartFieldsType([])
                onCreateChart()
              }else if(data.status === "session_expired") {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                swal({
                  title: "Session expirée",
                  text: data.message,
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((result) => {
                  console.log(result)
                });
                history.push("/sign-in");
              }else {
                swal({
                  title: "Erreur",
                  text: data.message,
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((result) => {
                  console.log(result)
                });
              }
            }
          );
        }
        
      }else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('user')
        swal({
          title: "Session expirée",
          text: "Votre session a expirée.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((result) => {
          console.log(result)
        });
        history.push("/sign-in");
      }
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      // const { teamName, desc} = { ...formData};
      saveNewChart()
      handleClose();
    };



    return( 
      <>
        <Modal  centered show={show} onHide={handleClose} animation={false}  backdrop="static" > 
        <Modal.Header>
          <Modal.Title className="h6">Créer un graphique</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form >
              <Row>
                  <Form.Group>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control  name="name"  onChange={handleInputChange}  required type="text"/>
                  </Form.Group>
              </Row>
              
              <Row>
                  <Form.Group>
                    <Form.Label>Type</Form.Label>

                  <Select
                    
                    options={chartTypeOptions}
                    onChange={handleChangeType}
                    placeholder="Sélectionnez..."
                  />
                  </Form.Group>
              </Row>
              <Row>
              {
                loading  ? (
                  <div style={{padding:10, textAlign:"center"}}>
                    <ScaleLoader color="#4A5073" />
                  </div>
                  
                ): (
                  
                    <Form.Group>
                      <Form.Label>Axe principale</Form.Label>

                    <Select
                      
                      options={fieldNameOptions}
                      onChange={handleChangeField}
                      placeholder="Sélectionnez..."
                    />
                    </Form.Group>
                
                )
              }
              </Row>
              <Row>
                  <Form.Group>
                    <Form.Label>Opération</Form.Label>

                  <Select
                    
                    options={chartFieldsType.length > 0 && chartFieldsType[0] == "NumberInput" ? chartOperations : chartOperationsWithCount}
                    onChange={handleChangeOperation}
                    placeholder="Sélectionnez..."
                  />
                  </Form.Group>
              </Row>
              <Row>
              {
                loadingValue  ? (
                  <div style={{padding:10, textAlign:"center"}}>
                    <ScaleLoader color="#4A5073" />
                  </div>
                  
                ): chartFieldsType.length > 0 && chartFieldsType[0] != "NumberInput" && chartOperation != "count" ? (
                  
                    <Form.Group>
                      <Form.Label>Champ de valeur</Form.Label>

                    <Select
                      
                      options={fieldValueOptions}
                      onChange={handleChangeFieldValue}
                      placeholder="Sélectionnez..."
                    />
                    </Form.Group>
                
                ) : (
                  <></>
                )
              }
              </Row>
          </Form>
         
        </Modal.Body>
          <Modal.Footer>
            <Button  variant="link" className="text-gray ms-auto" onClick={handleClose}>
              Annuler
            </Button>
            <Button disabled={chartName == '' || chartType == '' || chartFields.length == 0} variant="secondary" type="submit" onClick={handleSubmit}>
              { saving  ? (
                  <div style={{ textAlign:"center"}}>
                    <ScaleLoader height={25} color="#4A5073" />
                  </div>
                  
                ):(
                  "Créer"
                )
              }
            </Button>     
          </Modal.Footer>
      </Modal>
      </>
    )   
}

// export default Modaly