import React,{useState, useContext, useEffect, useRef } from "react";
import jwt_decode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import "leaflet/dist/leaflet.css";
import "../scss/leaflet.scss";
import { Col, Row, Card, Form, Button, Breadcrumb } from '@themesberg/react-bootstrap';
import MapAdvanced from '../components/MapAdvanced';
import { TeamMembersWidget } from "../components/Widgets";

// import { DateRangePicker } from 'react-date-range';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // style de base
import 'react-date-range/dist/theme/default'
import { useAuth } from './AuthContext';

export default () => {
  const [ formsLocation, setFormsLocation ] = useState([])
  const [ userId, setUserId]= useState();//useContext(AuthContext);
  const history = useHistory()
  const userCon =  JSON.parse(localStorage.getItem("user"))
  const [ formsId, setFormsId] = useState()
  const [user,setUser] =useState([])
  const [listForms,setListForms] =useState([])
  const [selectedForm,setSelectedForm] = useState('')
  const [selectedUser,setSelectedUser]   = useState('')
  const [lead,setLead] =useState('')
  const [listLead,setListlead] =useState([])

  const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const { token } = useAuth();


  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');


  function fetchFormsLocation(selectedForm,selectedUser,leadId,sDate,eDate) {
    
    // const {id_usercon,id_forms,id_user} = {id_usercon,id_forms,id_user}
    fetch('/api/getFormsLocationFilter', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({idConnected :userCon._id,idLead :leadId ,idForms: selectedForm,idUser: selectedUser,dateStart : sDate, datEnd : eDate}),
    }).then(
      response => response.json()
    ).then(
    data => {
            if(data.status === "success") {   
             
              setFormsLocation(data)
            }else if(data.status === "session_expired") {
              localStorage.removeItem('accessToken')
              localStorage.removeItem('user')
              swal({
                title: "Session expirée",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
              history.push("/sign-in");
            }else {
              swal({
                title: "Erreur",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
          }
      }
    )
  }

const fetchTeams = () => {
        fetch(`/api/getTeam/${userCon._id}`).then(
        response => response.json()
    ).then(
        data => setTeam(data["teams"])
    )
};

const fetchListForms= () => {
        fetch(`/api/getListForms/${userCon._id}`).then(
        response => response.json()
    ).then(
        data => setListForms(data["forms"])
    )
};


const fetchUsers = (leadId) => {
  fetch('/api/getUsersLead', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({userId :userCon._id,leadId : leadId}),
     }).then(
      response => response.json()
    ).then(data => setUser(data["users"]))

}

const fetchLead = () => {
  fetch(`/api/getLead/${userCon._id}`).then(
      response => response.json()
  ).then(
      data =>   setListlead(data["lead"])
  )
}


  useEffect(() => {

    fetchListForms()
    fetchUsers(lead)
    fetchLead()
    fetchFormsLocation(selectedForm,selectedUser,lead,startDate,endDate);
  },[userId,selectedForm,selectedUser,lead,startDate,endDate])

  const handleForms = (e) =>{
        console.log()
        // fetchFormsLocation(e.target.value,selectedUser,lead,startDate,endDate)
        setSelectedForm(e.target.value)
  }

  const handleUser = (e) =>{
    // fetchFormsLocation(selectedForm,e.target.value,lead,startDate,endDate )
    setSelectedUser(e.target.value)
  }

  const handleLead = (e) =>{
    console.log('my lead '+ e.target.value)
    setSelectedUser('')
    // fetchFormsLocation(selectedForm,selectedUser,e.target.value,startDate,endDate )
    fetchUsers(e.target.value)
    setLead(e.target.value)
  }

  const handleEndDate = (e) =>{
    // console.log('my lead '+ e.target.value)
    // fetchFormsLocation(selectedForm,selectedUser,lead,startDate,e.target.value )
    setEndDate(e.target.value)
  }

  const handleStartDate = (e) =>{
    // console.log('my lead '+ e.target.value)
    // fetchFormsLocation(selectedForm,selectedUser,lead,e.target.value,endDate )
    setStartDate(e.target.value) 
  }



  return (
    <>
    {/* <div id="bvfe">Mspionh</div> */}
    {/* onSubmit={handleSubmit} */}
    <Form  className="mt-4">    
          <Row>
          <Col className="mb-3"  md={2}> 
              <Form.Group id="note">
                <Form.Label>Responsable : </Form.Label>
                <Form.Select  onChange={handleLead}>
                        <option value="">Tous les responsables</option> 
                          {listLead &&
                              listLead.map(l => <option value={l._id}>{l.firstName}</option> )
                          }
                </Form.Select>
              </Form.Group>
            </Col>


          {/* <Col className="mb-3" md={1} >Affiche moi </Col> */}
            <Col className="mb-3" md={2} >
              <Form.Group id="firstName">
                <Form.Label>Nom du projet : </Form.Label>
                <Form.Select
                        onChange={handleForms}> 
                        <option value="">Tous les projets</option> 
                            {listForms &&
                                listForms.map(l => <option value={l._id}>{l.name}</option> )
                            }
                  
                </Form.Select>
              </Form.Group>
            </Col>
            {/* <Col className="mb-3" md={1} >de </Col> */}
            <Col className="mb-3"  md={2}> 
              <Form.Group id="note">
                <Form.Label> Nom d'utilisateur </Form.Label>
                <Form.Select  onChange={handleUser}>
                        <option value="">Tous les utilisateurs</option> 
                          {user &&
                              user.map(l => <option value={l._id}>{l.firstName}</option> )
                          }
                </Form.Select>
              </Form.Group>
            </Col>
            <Col className="mb-3"  md={2}> 
                <Form.Group>
                <Form.Label>Date de début</Form.Label>
                <Form.Control 
                  type="date" 
                  value={startDate} 
                  onChange={handleStartDate} 
                />
              </Form.Group>
            </Col>
            <Col className="mb-3"  md={2}> 
                  <Form.Group>
                  <Form.Label>Date de fin</Form.Label>
                  <Form.Control 
                    type="date" 
                    value={endDate} 
                    onChange={handleEndDate} 
                  />
                </Form.Group>
            </Col>
            

          </Row>

          
        </Form>


      <div > 
        { ( formsLocation.length == 0) ? (
                              <span>Aucune donnée</span>
                            ) : (<MapAdvanced id = 'map' marker = {formsLocation} />)
        }   
      </div>
    </>
  );
};

// export default YourComponent;