
export const Routes = {
    Presentation: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },
    DashboardData: { path: "/dashboard/data" },
    Prospection: { path: "/forms-project" },
    NewProspection: { path: "/new-project" },
    NewCanal: { path: "/new-canal" },
    Map: { path: "/map" },
    CreateForms: { path: "/create-forms/:projectId" },
    CreateCanal: { path: "/create-canal/:canalId" },
    CanalTable: { path: "/canal-project" },

    ModifyForms: { path: "/modifyforms/:projectId" },
    ViewProspection: { path: "/viewprospection/:id" },
    TeamTable: { path: "/team" },
    Carto : { path: "/carto" },
    Upgrade: { path: "/upgrade" },
    UserTables: { path: "/user" },
    CreateUser: { path: "/create-user" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    UpdateUser: { path: "/update-user/:userId" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    UpdateTeam: { path: "/add-user-team/:teamId" },
    Support : { path: "/support" },
    CreateCase : { path: "/create-case" },
    DetailsTicket:{path: "/details-ticket/:ticketId"},
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password/:token" },
    ChangeMail : { path: "/change-mail/:token" },
    ConfirmNewsLetter: { path: "/confirm-newsletter/:token" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/page-not-found/404" },
    ServerError: { path: "/server-error/500" },
    SuccessGoogleSignIn: { path: "/success/google/signin/:sessionID/:userId/:token" },
    TermsAndConditions: {path: "/terms-and-conditions"},
    Privacy: {path: "/privacy"},
    Adm: {path: "/adm"},
    DetailsAdmin: {path: "/adm/details-ticket/:ticketId"},
    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};