import React, {useState,useContext, useEffect} from "react";
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card, Form, Button, Breadcrumb,Badge,Container, InputGroup, ListGroup } from '@themesberg/react-bootstrap';
import {Link,useHistory, useLocation,useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';   
import { useAuth } from '../pages/AuthContext';
import { fr } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';

export default () => {

  const history  = useHistory();
  const location = useLocation();
  const { ticketId } = useParams();

  const { token } = useAuth();

  const userCon =  JSON.parse(localStorage.getItem("user"))
  
  const [ticket, setTicket] = useState()
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
    
  const fetchTicketInfos = () => { 
        fetch(`/api/getTicket/${ticketId}`, 
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                }
            }
          ).then(
          response => response.json()
      ).then(
        response => {
          console.log('ticket infos'+ JSON.stringify(response['ticket']) )
          setTicket(response['ticket'][0])
        }
    )
}

const fetchTicketComment = () => { 
  fetch(`/api/getTicketCommentAdmin/${ticketId}`, 
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
          }
      }
    ).then(
    response => response.json()
).then(
  response => {
    console.log('ticket infos'+ JSON.stringify(response) )
    setMessages(response['tickets'])
  }
)
}


useEffect(() => {
  fetchTicketInfos()
  fetchTicketComment()

}, [])
const ticketState = {
  0: 'Non traité',
  1: 'En cours',
  2: 'Cloturé'
};
const ticketColor = {
  0: '#A9A9A9', // Grey for trivial
  1: '#FFD700',  // Yellow for medium 
  2: 'green'   // Orange for high
};
// { _id: 1, user: 'FieldConnet', comment: "Bonjour,.Avez vous d'autres remarques, let's talk ?", createdDate: '17:34'},
//   { _id: 2, user: 'Your Answer', comment: 'Hi Neil, thanks for sharing your thoughts regarding Volt.', createdDate: '17:35'},

const handleSendMessage =  async (e) => { 
  if (newMessage.trim()) {
    

    await fetch('/api/commentTicket', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({idTicket:ticketId,user: userCon._id,comment: newMessage,email:ticket.owner.email}),
    }).then(
      response => response.json()
    ).then(
      data => { 
        if (data.status === 'success') {
         const new_comment =  data['ticket']
         console.log(data['ticket'])
          setMessages([
            ...messages,
            {_id :new_comment._id, user: new_comment.user, comment: newMessage,createdDate:new_comment.createdDate},
          ]);
          setNewMessage('');
        } else {
          toast.error(data['message'], {
              style: {
                border: '1px solid #713200',
                padding: '16px',
              }});
          console.error(data.message);
        }
      }
    )

  }
};
return( 
    <>
    <div style={{  marginTop: '-20px'}} className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item  linkAs={Link} linkProps={{ to: '/adm' }}>Support</Breadcrumb.Item>
            <Breadcrumb.Item active>Ticket : { ticket?ticket.object:'' }</Breadcrumb.Item>
          </Breadcrumb>
          <h4></h4>
        </div>
      </div>
      <div> 
    <Row>
        {/* <Col xs={16} xl={8}> */}
        <Col md={4}>
    <div className="card mb-3 p-3 shadow-sm">
        <div className="card-body">
            <h5 className="card-title mb-3 text-primary">
                <i className="bi bi-info-circle-fill me-2"></i>Details Ticket
            </h5>
            <div className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center">
                    <strong className="text-secondary">ID Ticket :</strong>
                    <span className="text-dark">{ticket ? ticket._id : ''}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <strong className="text-secondary">Objet :</strong>
                    <span className="text-dark">{ticket ? ticket.object : ''}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <strong className="text-secondary">Description :</strong>
                    <span className="text-dark">{ticket ? ticket.desc : ''}</span>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                    <strong className="text-secondary">Statut :</strong>
                    <Badge
                        className="badge-lg"
                        style={{
                            backgroundColor: ticket ? ticketColor[ticket.state] : '',
                            color: 'white',
                            minWidth: '80px',
                            textAlign: 'center',
                            padding: '5px',
                            borderRadius: '8px'
                        }}
                    >
                        {ticket ? ticketState[ticket.state] : ''}
                    </Badge>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <strong className="text-secondary">Date de création :</strong>
                    <span className="text-dark">{ticket ? ticket.createdDate : ''}</span>
                </div>
            </div>
        </div>
    </div>
</Col>
     
    <Col md={8}>
    <Row className="justify-content-center mt-4">
        {/* <Col md={10}> */}
          {/* <Button variant="link" className="mb-3">
            &larr; Retour 
          </Button> */}
          
            <ListGroup className="mb-3">
                {messages.map((message) => {
                    // Debugging: log each message object to inspect its structure
                    console.log('Message:', message);

                    // Check values directly to ensure they are strings or numbers
                    const isCurrentUser = message.user._id === userCon._id;
                    const userName = isCurrentUser ? 'Votre reponse' : message.user.firstName;
                    const formattedDate = format(parseISO(message.createdDate), 'dd MMM yyyy, HH:mm'); // Correct formatting

                    // Debug: log formatted date and other values
                    console.log('Formatted Date:', formattedDate);
                    console.log('User Name:', userName);

                    return (
                    <ListGroup.Item
                        key={message._id}
                        className={`d-flex ${isCurrentUser ? 'justify-content-end ms-lg-6' : 'justify-content-start'}`}
                        style={{
                        backgroundColor: isCurrentUser ? '#2c3e50' : '#fff',
                        color: isCurrentUser ? '#fff' : '#000',
                        borderRadius: '10px',
                        marginBottom: '10px',
                        }}
                    >
                        <div className={`p-2 ${isCurrentUser ? 'text-end' : 'text-start'}`} style={{ maxWidth: '70%' }}>
                        <strong>{userName}</strong> <Badge bg="secondary" className="ms-2">{formattedDate}</Badge>

                        <p className="m-0">{message.comment}</p>
                        </div>
                    </ListGroup.Item>
                    );
                })}
            </ListGroup>

          <InputGroup className="mb-3">
            <Form.Control className="border-0 shadow mb-4 form-control" style={{ height: '100px'}}
              placeholder="Your Message"
              value={newMessage} as = 'textarea'
              onChange={(e) => setNewMessage(e.target.value)}
            />
            
          </InputGroup>
          <div className="d-flex justify-content-between">
              <Button variant="outline-secondary" onClick={handleSendMessage} className="d-inline-flex align-items-center text-dark btn btn-secondary">
                  Envoyer
                </Button>          
            </div>
        {/* </Col> */}
      </Row>
      </Col>
      </Row> 
    </div>
    <div>
        <Toaster   
          position="top-right"
          reverseOrder={true} 
          
        />
      </div>
    </>
  );
};
