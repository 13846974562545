import React, { useState, useEffect, useCallback } from "react";
import { Breadcrumb, Button, Col, Row, Card, Modal,Alert  } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams,useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { useAuth } from '../../pages/AuthContext';
// import toast from 'react-hot-toast'; 
// import {Link,useHistory, useLocation,useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';   

export default () =>  {
  const [user, setUser] = useState([]);
  const [team, setTeam] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const { token } = useAuth();
  const { teamId } = useParams();
  const history  = useHistory();

  const getInitials = useCallback((fullName) => { 
    return fullName.split(' ').map(name => name.charAt(0).toUpperCase()).join('');
  }, []);

  const fetchTeamAndUsers = useCallback(async () => {
    const userCon = JSON.parse(localStorage.getItem("user"));
    if (!userCon) return;

    try {
      const [usersResponse, teamResponse] = await Promise.all([
        fetch(`/api/getAllUsers/${userCon._id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        }),
        fetch(`/api/getTeamInfos/${teamId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        })
      ]);

      let usersData = await usersResponse.json();
      const teamData = await teamResponse.json();
      usersData.users = usersData.users.map(user => ({
        ...user,
        EQ:  user.team?.some(item => item.name === teamData.team.name) ?1:0 
      }));
    
      setUser(usersData.users.sort((a, b) => b.EQ - a.EQ) || []);
      setTeam(teamData.team || {});
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [token, teamId]);

  useEffect(() => {
    fetchTeamAndUsers();
  }, [fetchTeamAndUsers]);

  const isRowSelected = useCallback((row) => {
    return team.user_list?.includes(row._id);
  }, [team.user_list]);

  const handleRowSelected = useCallback(({ selectedRows }) => {
    setSelectedRows(selectedRows);
    setUserList(selectedRows.map(user => user._id));
  }, []);

  const handleAdd = async () => {
    try {
      const response = await fetch('/api/updateTeam', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${token}` },
        body: JSON.stringify({ id: team._id, name: team.name, desc: team.desc, user_list: userList }),
      });

      const data = await response.json();
      if (data.status === 'success') {
        // toast.success('Modification réussie');

        toast.success('Ajouts utilisateurs réussie', {
          // duration: 50000, 
          style: {
            border: '1px solid #713200',
            padding: '16px',
          }});

        history.push("/team");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error updating team:', error);
      toast.error('Une erreur est survenue');
    }
    setShowModal(false);
  };

  const toggleModal = useCallback(() => setShowModal(prev => !prev), []);

  const columns = [
    {
      name: '',
      selector: row => !row.image ? (
        <div className="avatar-sm bg-secondary d-flex align-items-center justify-content-center rounded me-3">
          <small className="fw-bold p-1">{getInitials(`${row.name} ${row.firstName}`)}</small>
        </div>
      ) : (
        <img src={row.image} alt="Photo" className="avatar-sm rounded-circle me-3" />
      ),
      width: '60px',
      sortable: true
    },
    {
      name: 'NOM',
      selector: row => <Card.Link as={Link} className="text-primary fw-bold" to={`/update-user/${row._id}`}>{row.name}</Card.Link>,
      width: '200px'
    },
    { name: 'PRÉNOM', selector: row => row.firstName, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    { name: 'NUMÉRO', selector: row => row.phoneNumber, sortable: true },
    { name: 'EQUIPE', selector: row => row.team?.map(team => team.name).join(', '), sortable: true },
    { name: 'RESPONSABLE', selector: row => row.leadId?.firstName || '', sortable: true },
    { name: 'PLATEFORME', selector: row => row.plateforme, sortable: true },
    // { name: 'EQ', selector: row =>  row.team?.some(item => item.name === team.name) ?1:0, sortable: true }
    // { name: 'GENRE', selector: row => row.gender, sortable: true }
    // { name: 'RÔLE', selector: row => row.role, sortable: true },
    // { name: 'ACTION', selector: row => <></>, sortable: true }
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        backgroundColor: '#1f2334',
        color: 'white'
      },
    },
  };

  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /> FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/team' }}>Equipe</Breadcrumb.Item>
            <Breadcrumb.Item active>{team.name}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={12} lg={3} xl={8}>
          <Alert key= 'success' variant= 'success'>
              Vous pouvez ajouter ou retirer des utilisateurs dans l'équipe <b>{team.name}</b> en cochant ou décochant les cases dans le tableau et les sauvegarder.
          </Alert>
          </Col>
          <Col className="text-end">
            <Button 
              variant="secondary" 
              onClick={toggleModal}
              // disabled={selectedRows.length === 0}
            >
              Sauvegarder
            </Button>
          </Col>
        </Row>
      </div>

      <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
          <DataTable
            columns={columns}
            data={user}
            selectableRows
            onSelectedRowsChange={handleRowSelected}
            pagination
            highlightOnHover
            pointerOnHover
            customStyles={customStyles}
            selectableRowSelected={isRowSelected}
            defaultSortField="EQ" // Optional: If you want to set a default column to sort
            defaultSortAsc={false}

          />
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter/retirer des Utilisateurs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Voulez-vous vraiment sauvegarder ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Annuler
          </Button>
          <Button variant="success" onClick={handleAdd}>
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster   
          position="top-right"
          reverseOrder={true} 
          
        />

    </>
  );
};

// export default UserTable;
