
import React,{useState, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt,faPhone, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup,Spinner, Image } from '@themesberg/react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'; 
import { Routes } from "../routes";
import BgImage from "../assets/img/illustrations/signin.svg";
import { parseISO } from "date-fns/esm";
import Swal from 'sweetalert';
import NotFoundImage from "../assets/img/homelogo.png";
import PasswordChecklist from "react-password-checklist"


export default () => {
  const history = useHistory();
  const recaptchaRef = useRef();
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [newsletter, setNewsletter] = useState(true);
  const [username, setUsername] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [company, setCompany] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inLoading, setInLoading] = useState(false)
  const [passwordValidation, setPasswordValidation] = useState(null)
  const [phoneNumberValidation, setPhoneNumberValidation] = useState(null)
  
  const handleCaptcha = (token) => {
    setCaptchaToken(token);
  };
  
  const handlePasswordValidity = (isValid) => {
    setIsValidPassword(isValid)
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Envoyer les données au backend
    
    if (!captchaToken) {
      swal({
        title: "Attention",
        text: "Veuillez vérifier le CAPTCHA",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((result) => {
        console.log(result)
      });
      return;
    }

    


    if(password != confirmPassword) {
      setPasswordValidation("Les deux mot de passe ne correspondent pas.")
      
    } else {
      // Check phone number validity
      let reg = /^(32|33|34|38|37)+[\d]{7}$/;
      if (!reg.test(phoneNumber)) {
        setPhoneNumberValidation("Le numéro téléphone n'est pas valide.")
      } else {
        setPasswordValidation(null)
        setPhoneNumberValidation(null)
        setInLoading(true)
        let userPhoneNumber = `0${phoneNumber}`
  
        fetch('/api/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({username,firstname,email,phoneNumber:userPhoneNumber,company,password, captchaToken, newsletter}),
        }).then(
          response => response.json()
        ).then(
          async (data) => {
            if (data.status === "success") {
              // Redirection vers le tableau de bord
              // toast.success("Une .", {
              //   style: {
              //     border: '1px solid #713200',
              //     padding: '16px',
              //   }});
              // Un message avec un lien de confirmation vous a été envoyé par mail. Veuillez suivre ce lien pour activer votre compte.
              history.push("/sign-in?successSignup=1");
            } else {
              setInLoading(false)
              // Affichage d'un message d'erreur
              console.error(data.message);
              await recaptchaRef.current.reset();
              setCaptchaToken(null)
  
                swal({
                  title: "Erreur",
                  text: data.message,
                  icon: "error",
                  buttons: true,
                  dangerMode: true,
                })
                .then((result) => {
                  console.log(result)
                });
            }
          }
        );

      }
      
    }
   

  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <div>
            <Toaster   
              position="top-right"
              reverseOrder={false} />
          </div>
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={Routes.Presentation.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Revenir à la page d'accueil
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
          
            <Col xs={12} className="d-flex align-items-center justify-content-center">
            
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-700">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <Image src={NotFoundImage} className="img-fluid w-50" />
                  <h5 style={{marginTop:5}} className="mb-0">Créer votre compte</h5>
                </div>
                <Form onSubmit={handleSubmit} className="mt-4">
                 
                  <Row>
                    <Col>
                      <Form.Group id="username" className="mb-4">
                        <Form.Label>Nom</Form.Label>
                        <InputGroup>
                          <Form.Control autoFocus required type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group id="firstname" className="mb-4">
                        <Form.Label>Prénom</Form.Label>
                        <InputGroup>
                          <Form.Control autoFocus required type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)}/>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  

                  

                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Adresse email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="phoneNumber" className="mb-4">
                    <Form.Label>Téléphone</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPhone} />(+261)
                      </InputGroup.Text>
                      <Form.Control isInvalid={phoneNumberValidation} autoFocus required type="text" placeholder="3xxxxxxxx" value={phoneNumber} onChange={(e) => setphoneNumber(e.target.value)} />
                      <Form.Control.Feedback type="invalid">
                        {phoneNumberValidation}
                      </Form.Control.Feedback>
                    
                    </InputGroup>
                  </Form.Group>


                  <Form.Group id="company" className="mb-4">
                    <Form.Label>Entreprise</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faBuilding} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="text" placeholder="Nom de votre entreprise" value={company} onChange={(e) => setCompany(e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                  
                  <Row>
                    <Col>
                      <Form.Group id="pass" className="mb-4">
                        <Form.Label>Nouveau mot de passe</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control isInvalid={passwordValidation} required type="password"  value={password} onChange={(e) => setPassword(e.target.value)} />
                          <Form.Control.Feedback type="invalid">
                            {passwordValidation}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group id="confirmPassword" className="mb-4">
                        <Form.Label>Confirmer le mot de passe</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control isInvalid={passwordValidation} required type="password"  value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                          <Form.Control.Feedback type="invalid">
                            {passwordValidation}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      
                    </Col>
                  </Row>

                  <Row>
                    <p style={{fontSize:14, color:"red"}}> *Critères du mot de passe</p>
                    <PasswordChecklist
                          style={{color:"#031667"}}
                          rules={["minLength","specialChar","number","capital","match"]}
                          minLength={8}
                          value={password}
                          valueAgain={confirmPassword}
                          onChange={(isValid) => handlePasswordValidity(isValid)}
                          messages={{
                            minLength: "Le mot de passe doit comporter plus de 8 caractères.",
                            specialChar: "Le mot de passe doit comporter des caractères spéciaux.",
                            number: "Le mot de passe doit comporter un chiffre.",
                            capital: "Le mot de passe doit comporter une majuscule.",
                            match: "Les deux mots de passe doivent être identiques.",
                          }}
                        />
                        
                  </Row>
                          <br />
                  

                  <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input defaultChecked={newsletter} onChange={(e) => setNewsletter(e.target.checked)} id="newsletter" className="me-2" />
                    <FormCheck.Label htmlFor="newsletter">
                      Je m'inscris au newsletter de EFieldConnect pour ne pas manquer les mis à jour
                    </FormCheck.Label>
                  </FormCheck>
                  
                  
                  <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input required id="terms" className="me-2" />
                    <FormCheck.Label htmlFor="terms">
                      J'accepte le <Card.Link as={Link} to={Routes.TermsAndConditions.path} target="_blank" >Termes et conditions de FieldConnect</Card.Link>
                    </FormCheck.Label>
                  </FormCheck>

                  
                  {/* <GoogleReCaptchaProvider reCaptchaKey="6LebulwqAAAAACw58yHnqwteIRROg5DZ1bf0hQ4H">
                    <GoogleReCaptcha onVerify={handleCaptcha} />
                  </GoogleReCaptchaProvider> */}
                  <ReCAPTCHA
                    sitekey="6LcPxm0qAAAAANR5V0YfE-3Dg-kLAh9ZRH4fkm00"
                    onChange={handleCaptcha}
                    ref={recaptchaRef}
                  />
                  <br />
                  <Button disabled={!isValidPassword} variant="primary" type="submit" className="w-100">
                    {
                      inLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        "Créer un compte"
                      )
                    }
                  </Button>
                </Form>

                {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">Ou</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div> */}
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                  Vous avez déjà un compte ?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Se connecter `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
