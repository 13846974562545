import React, {useState, useEffect,useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArchive,faEye , faEllipsisH,faUserSlash, faUserCheck, faBan ,faHome,faPlus,faEdit,faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { ButtonGroup,Dropdown ,Breadcrumb,Button,Col, Row , Nav, Card, Table, Pagination,Form } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import { Link,useHistory  } from 'react-router-dom';
import { Toaster } from 'react-hot-toast'; 
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv';
import { useAuth } from './AuthContext';

export default () =>  {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [user, setUser] = useState([])
  const userCon =  JSON.parse(localStorage.getItem("user"))
  const [myrole, setMyrole] = useState('');
  const { token } = useAuth();

  const [allCanal, setAllCanal] = useState([])

  
  const columns = [
    {
      name: 'Nom du canal',
      selector: row =><Card.Link as={Link} className="text-primary fw-bold" to={{ pathname: `/create-canal/${row._id}`}} > {row.name}</Card.Link>,
      sortable: true,
      width: '200px'
    },
    {
      name: 'Desc',
      selector: row => <Card.Link as={Link} className="text-primary fw-bold" to={{ pathname: `/update-user/${row._id}`}} > {row.note}</Card.Link>,
      width: '200px'
    },
    {
      name: 'PRÉNOM',
      selector: row => row.user,
      sortable: true,
    },
  
    {
      name: 'ACTION',
      cell:  row =>  (row._id == userCon._id || (myrole == 'RESPONSABLE' && ['RESPONSABLE','ADMIN'].includes(row.role))  )? (''):(
      <Dropdown>
        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
          <span className="icon icon-sm">
            <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>
            <Link to={{ pathname: `/update-user/${row._id}`, state: { user : row.data } }}>
                          <FontAwesomeIcon  icon={faEdit} className="me-2" /> Modifier
              </Link>
          </Dropdown.Item>
          <Dropdown.Item>
               {
                  row.state == 'active'?(<><FontAwesomeIcon  className="text-danger me-2"  icon={ faArchive    } /><span style={{color:'black'}}>Archiver</span></> ):
                  (<><FontAwesomeIcon  className="text-infos me-2"  icon={ faUserCheck    } /><span style={{color:'black'}}>Activer</span></> )
               }
          </Dropdown.Item>
          <Dropdown.Item className="text-danger">
              {/* <Link > */}
                <FontAwesomeIcon  className="text-danger me-2" icon={faTrashAlt} /><span style={{color:'black'}}>Supprimer</span> 
              {/* </Link> */}
          </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
),
          
      // sortable: true,
    }
  ];


      


  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        backgroundColor: '#1f2334', 
        color : 'white'// You can set your desired background color here
      },
    },
  };
useEffect(() => {
      fetch(`/api/getUser/${userCon._id}`).then(
      response => response.json()
    ).then(
      response => {
        setMyrole(response[0].role)
      }
)}, [])

useEffect( 
    ()=> {
      fetch(`/api/getAllCanal/${userCon._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }).then(response => response.json())
        .then(data => {
          setAllCanal(data["canals"]);
          console.log('all canal'+JSON.stringify(data))
        })
        .catch(error => {
          console.error('Error:', error);
        })
}, [])


const delUser = (id,email,phoneNumber) => {
      
    swal({
      title: "Confirmation",
      text: "Voulez-vous supprimer définitivement cet utilisateur ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((test) => {
      if (test) {
        fetch('/api/deleteUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({id,email,phoneNumber}),
        }).then(response => response.json()).then(
          data => {
            if(data.status === 'success'){
              const updatedUsers = user.filter((us) => us._id !== id);
              setUser(updatedUsers);
              swal(data['message'] + "!", {
                icon: "success",
              });
            }else{
              swal(data['message'], {
                icon: "error",
              });
            }
            
          }
        )
      } 
    });
  }


  const filteredData = useMemo(() => {
    return user.filter(
		item => (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))  || 
        (item.email && item.email.toLowerCase().includes(filterText.toLowerCase())) ||  
        (item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.phoneNumber && item.phoneNumber.toLowerCase().includes(filterText.toLowerCase()))
	)
  }, [filterText,user]);



  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} />FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item active>Canaux</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
        <Col xs={8} md={6} lg={3} xl={4}>
        <Button variant="primary" size="sm" as={Link} to={Routes.NewCanal.path}><FontAwesomeIcon icon={faPlus} className="me-2" />Créer un canal</Button>
        </Col>
        </Row>
      </div>
      {/* striped */}
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">

        <DataTable
            columns={columns}
            data={allCanal}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            customStyles={customStyles}
        />

      </Card.Body>
    </Card>
    <Toaster   
          position="top-right"
           />
      
    </>


    
  );
};

// export default MyDataTable;
