import React, {useState,useContext, useEffect} from "react";
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card, Form, Button, Breadcrumb } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import toast, { Toaster } from 'react-hot-toast'; 
import { useAuth } from '../pages/AuthContext';

export default () => {
  const history = useHistory()
  const { token } = useAuth();
  const [ticket, setTicket] = useState({
    object : '',
    desc:'',
    type:'question',
    severity : 'trivial'
  })
  const userCon =  JSON.parse(localStorage.getItem("user"))
  
  const handleInputChange = (e) => {  
    const name  = e.target.name;
    let value = e.target.value

    setTicket((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    console.log('forma vale'+ JSON.stringify( e.target.value) )
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await fetch('/api/createTicket', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({...ticket,owner : userCon._id,company : userCon.company,mail:userCon.email}),
    }).then(
      response => response.json()
    ).then(
      data => {
        if (data.status === 'success') {
          toast.success('Création réussie', {
            style: {
              border: '1px solid #713200',
              padding: '16px',
            }});
          history.push("/support");
        } else {
          toast.error(data['message'], {
              style: {
                border: '1px solid #713200',
                padding: '16px',
              }});
          console.error(data.message);
        }
      }
    )

    
  };
  const severityColors = {
    trivial: '#A9A9A9', // Grey for trivial
    low: '#ADD8E6',     // Light blue for low
    medium: '#FFD700',  // Yellow for medium
    high: '#FF8C00',    // Orange for high
    critical: '#FF0000' // Red for critical
  };

  return ( 
    <>
    <div style={{  marginTop: '-20px'}} className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item  linkAs={Link} linkProps={{ to: '/support' }}>Support</Breadcrumb.Item>
            <Breadcrumb.Item active>Créer un ticket</Breadcrumb.Item>
          </Breadcrumb>
          <h4>En quoi pouvons-nous vous aider ?</h4>
        </div>
      </div>

  <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleSubmit} className="mt-4">
        <Row>
                <Col className="mb-3"  md={3}> 
                    <Form.Group id="type">
                    <Form.Label>Type de demande</Form.Label>
                        <Form.Select name="type" value={ticket.type} 
                            onChange={handleInputChange}> 
                            <option value="question">Simple question</option>
                            <option value="bug">Signaler un Bug</option>
                            <option value="assistance">Demande Assistance</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

          <Row>
            <Col className="mb-3" md={3} >
              <Form.Group id="object">
                <Form.Label>Objet</Form.Label>
                <Form.Control name="object" onChange={handleInputChange}  required type="text"/>
              </Form.Group>
            </Col>
           
          </Row>
          <Row>
                <Col className="mb-3" md={3} >
                    <Form.Group id="desc" >
                        <Form.Label>Description</Form.Label>
                        <Form.Control  name="desc" onChange={handleInputChange}  required as="textarea"/>
                    </Form.Group>
                </Col>
          </Row> 
            <Row>
                <Col className="mb-3"  md={3}> 
                    <Form.Group id="severity">
                    <Form.Label>Sévérité</Form.Label>
                        <Form.Select name="severity"  value={ticket.severity}  style={{ backgroundColor: severityColors[ticket.severity], color: '#000' }} 
                            onChange={handleInputChange}> 
                            <option value="trivial">Très faible</option>
                            <option value="low">Faible</option>
                            <option value="medium">Normale</option>
                            <option value="high">Élevée</option>
                            <option value="critical">Urgente</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

         

          <Row>
            <Col className="mb-3" md={3}>
            </Col>
          </Row>
          <div className="mt-3">
            <Button variant="primary" type="submit">Créer</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
    <div>
        <Toaster   
          position="top-right"
          reverseOrder={true} />
      </div>
    </>   
  );
};
