import React, {useState, useEffect,useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome,faPlus,faEdit,faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb,Button,Col, Row , Nav, Card, Table, Pagination,Form,Badge  } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import { Link,useHistory  } from 'react-router-dom';
import { Toaster } from 'react-hot-toast'; 
import DataTable from 'react-data-table-component';
import { useAuth } from './AuthContext';

export default () =>  {
  const userCon =  JSON.parse(localStorage.getItem("user"))
  const [myrole, setMyrole] = useState('');
  const [tickets, setTickets] = useState('');
  const { token } = useAuth();

  const severityColors = {
    trivial: '#A9A9A9', // Grey for trivial
    low: '#ADD8E6',     // Light blue for low
    medium: '#FFD700',  // Yellow for medium
    high: '#FF8C00',    // Orange for high
    critical: '#FF0000' // Red for critical
  };
  const valuesBadge = {
    trivial: 'Très faible', // Grey for trivial
    low: 'Faible',     // Light blue for low
    medium: 'Normale',  // Yellow for medium
    high: 'Élevée',    // Orange for high
    critical: 'Urgente' // Red for critical
  };
  
  const ticketState = {
    0: 'Non traité',
    1: 'En cours',
    2: 'Cloturé'
  };

  const ticketColor = {
    0: '#A9A9A9', // Grey for trivial
    1: '#FFD700',  // Yellow for medium
    2: 'green'   // Orange for high
  };
  const columns = [
    { name: 'ID Ticket', selector: row => <Card.Link as={Link} className="text-primary fw-bold" to={{ pathname: `/adm/details-ticket/${row._id}`}} > {row._id}</Card.Link>, sortable: true },
    
    { name: 'Demandeur', selector: row => row.owner.firstName, sortable: true },
    { name: 'COMPANY', selector: row => row.company.name, sortable: true },


    { name: 'Créé', selector: row => row.createdDate, sortable: true },
    { name: 'Objet', selector: row => row.object, sortable: true },
    { name: 'Description', selector: row => row.desc, sortable: true },
    { name: 'Sévérité', selector: row =><Badge className="badge-lg" style={{ backgroundColor: severityColors[row.severity], color: 'black',width: 80 }}>{valuesBadge[row.severity] }</Badge>, sortable: true },
    { name: 'Type de cas', selector: row => row.type, sortable: true },
    { name: 'Statut', selector: row => (
        <div>
            <select 
                style={{ cursor: 'pointer', textAlign: "center", backgroundColor: ticketColor[row.state], color: 'black',width: 80  }} 
                value={row.state} 
                aria-label="Default select example" 
                name="role" 
                // onChange={(event) => handleInputChangeStatus(row.ID, event)}
            >
                <option value="">--</option>
                <option className='select-approuved' value="0">Non traité</option>
                <option className='select-rejected' value="1">En cours</option>
                <option className='select-pending' value="2">Cloturé</option>
            </select>
        </div>
    )}
  ];
  
  const fetchTicket = () => {
    
    fetch(`/api/getAllTicketAdmin/${userCon._id}`,{
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
    }})
    .then(
      response => response.json()
    ).then(
        data => setTickets(data["tickets"])
    )
  }
  useEffect( () => {
    fetchTicket()
}, [])

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        backgroundColor: '#1f2334', 
        color : 'white'// You can set your desired background color here
      },
    },
  };
  const data = [];

return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
         
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
        <Col xs={8} md={6} lg={3} xl={4}>
        </Col>
        </Row>
      </div>
      {/* striped */}
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <DataTable
            columns={columns}
            persistTableHead 
            data={tickets}
            noDataComponent={<div style={{ padding: '20px' }}>Aucune ticket Créé.</div>} 
            pagination
            customStyles={customStyles}
        />
        
                
        </Card.Body>
        </Card>
    <Toaster position="top-right"/>
    </>
  );
};

// export default MyDataTable;
