import React,{useState, useContext, useEffect, useMemo} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister,faChartLine, faCloudUploadAlt,faUsers,faTable , faDatabase,faUser,faCity , faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
// import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import {Form ,  Breadcrumb,Button,Col, Row , Nav, Card, Image, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';

import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import Chart  from 'react-apexcharts';
import { CreateChart } from "../../components/CreateChart"; 


import { useAuth } from '../AuthContext';

export default () => {
  const history = useHistory()
  const [selectedForm,setSelectedForm] = useState('')
  // const [lead,setLead] =useState('')
  const [listLead,setListlead] =useState([])
  const [showModal, setShowModal] = useState(false);

  const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  
  const userCon  =  JSON.parse(localStorage.getItem("user"))
  const [ leadId, setLeadId] = useState("")
  const [ selectedUser, setSelectedUser] = useState("")
  const [ allFormsData, setAllFormsData ] = useState([])

  const [dailyOptions, setDailyOptions] = useState([]);
  const [dailySeries, setDailySeries] = useState([]);
  const [formsLead, setFormsLead] = useState([])
  const [activeButton, setActiveButton] = useState('daily');
  const { token } = useAuth();
  const [user,setUser] =useState([])

   
  const [listLeadName, setListLeadName] = useState([]);
  const [listLeadNb, setListLeadNb] = useState([]);


  const [listFormsName, setListFormsName] = useState([]);
  const [listFormsNb, setListFormsNb] = useState([]);

  const [listForms,setListForms] =useState([])

  const [chartData,setChartData] =useState([])

  const handleCloseModal = () => setShowModal(false)


  const fetchChartData = () => {
    if(selectedForm != "") {
      fetch(`/api/fetchChartData/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({userId :userCon._id,formId:selectedForm})
      }).then(response => response.json())
        .then(
        data => {
          setChartData(data['chart'])
        }
      );
    }
    
  }

  const handleCreateChart = (newChart) => {

    
    };

    useEffect(() => {
      fetchListForms()
      // fetchNbteam();
      fetchLead()
    }, []);
  
  
    
  // function fetchNbteam(){

  //   fetch(`/api/getNbteam/${userCon._id}`).then(
  //     response => response.json()
  //   ).then(
  //       data => {
  //         setNbTeam(data['nb'])
  //       })
  // }

  const fetchListForms= () => {
    fetch(`/api/getListForms/${userCon._id}`).then(
    response => response.json()
).then(
    data => setListForms(data["forms"])
)
};


  
  
  

    const  fetchFormsResponse = (leadId,sDate,eDate) => {
      if(selectedForm != "" ) {
        fetch(`/api/getFormsDataResponse`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({userId :userCon._id,formId:selectedForm, userSelecteId:selectedUser, leadId : leadId ,dateStart : sDate, datEnd : eDate}),
        }).then(
          response => response.json()
        ).then(
          data => {
           
            if(data.status === "success") {
              // console.log('forms data'+JSON.stringify(data) )
              setAllFormsData(data['chartData'])

            }else if(data.status === "session_expired") {
             
              history.push("/sign-in");
            }else {
              swal({
                title: "Erreur",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
            }
            
          }
        )
      }
      
    }



const fetchUsers = (leadId) => {
  fetch('/api/getUsersLead', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({userId :userCon._id,leadId : leadId}),
     }).then(
      response => response.json()
    ).then(data => setUser(data["users"]))

}
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  
const fetchLead = () => {
  fetch(`/api/getLead/${userCon._id}`).then(
      response => response.json()
  ).then(
      data =>   setListlead(data["lead"])
  )
}


  useEffect(() => {
    fetchFormsResponse(leadId,startDate,endDate);
  }, [selectedForm, selectedUser, leadId,startDate,endDate]);




  useEffect(() => {
    fetchChartData()
  }, [selectedForm]);


  



  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartDate = (e) => {
    console.log('start date');
    setStartDate(e.target.value);
  };

  const handleForms = (e) =>{
    setSelectedForm(e.target.value)
}

const handleUser = (e) =>{
  setSelectedUser(e.target.value)
}

const handleLead = (e) =>{
  // console.log('my lead '+ e.target.value)
  setSelectedUser('')
  fetchUsers(e.target.value)
  setLeadId(e.target.value)
}

  const LineChart = ({ chart }) => {

   
    
    const chartOptions = {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    };

    const chartSeries = [{
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100]
    }, {
      name: 'series2',
      data: [11, 32, 45, 32, 34, 52, 41]
    }]

    return (
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="area"
        height="350"
      />
    );
  };

  

  const ScatterChart = ({ chart }) => {
    

    const _series = [{
      name: "SAMPLE A",
      data: [
      [16.4, 5.4], [21.7, 2], [25.4, 3], [19, 2], [10.9, 1], [13.6, 3.2], [10.9, 7.4], [10.9, 0], [10.9, 8.2], [16.4, 0], [16.4, 1.8], [13.6, 0.3], [13.6, 0], [29.9, 0], [27.1, 2.3], [16.4, 0], [13.6, 3.7], [10.9, 5.2], [16.4, 6.5], [10.9, 0], [24.5, 7.1], [10.9, 0], [8.1, 4.7], [19, 0], [21.7, 1.8], [27.1, 0], [24.5, 0], [27.1, 0], [29.9, 1.5], [27.1, 0.8], [22.1, 2]]
    },{
      name: "SAMPLE B",
      data: [
      [36.4, 13.4], [1.7, 11], [5.4, 8], [9, 17], [1.9, 4], [3.6, 12.2], [1.9, 14.4], [1.9, 9], [1.9, 13.2], [1.4, 7], [6.4, 8.8], [3.6, 4.3], [1.6, 10], [9.9, 2], [7.1, 15], [1.4, 0], [3.6, 13.7], [1.9, 15.2], [6.4, 16.5], [0.9, 10], [4.5, 17.1], [10.9, 10], [0.1, 14.7], [9, 10], [12.7, 11.8], [2.1, 10], [2.5, 10], [27.1, 10], [2.9, 11.5], [7.1, 10.8], [2.1, 12]]
    },{
      name: "SAMPLE C",
      data: [
      [21.7, 3], [23.6, 3.5], [24.6, 3], [29.9, 3], [21.7, 20], [23, 2], [10.9, 3], [28, 4], [27.1, 0.3], [16.4, 4], [13.6, 0], [19, 5], [22.4, 3], [24.5, 3], [32.6, 3], [27.1, 4], [29.6, 6], [31.6, 8], [21.6, 5], [20.9, 4], [22.4, 0], [32.6, 10.3], [29.7, 20.8], [24.5, 0.8], [21.4, 0], [21.7, 6.9], [28.6, 7.7], [15.4, 0], [18.1, 0], [33.4, 0], [16.4, 0]]
    }];
  
    var _options = {
      chart: {
        height: 350,
        type: 'scatter',
        zoom: {
          enabled: true,
          type: 'xy'
        }
      },
      xaxis: {
        tickAmount: 10,
        labels: {
          formatter: function(val) {
            return parseFloat(val).toFixed(1)
          }
        }
      },
      yaxis: {
        tickAmount: 7
      }
    };
  
    return  <Chart
                options={_options}
                series={_series}
                type="scatter"
                height="350"
              />    
  };

  const computeOccur = (arr) => {
    return arr.reduce((acc, valeur) => {
      acc[valeur] = (acc[valeur] || 0) + 1;
      return acc;
    }, {});
  };
  
  const PieChart = ({ chart }) => {
    let field = chart.fields[0]
    let rowData = []
    for(let i=0;i<allFormsData.length;i++) {
      let data = JSON.parse(allFormsData[i].response)
      rowData.push( data[field])
      
    }

    const chartData = computeOccur(rowData);    

    let _series = Object.values(chartData)
  
    var _options = {
      chart: {
      height: 350,
      type: 'pie',
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      }
    },
    labels: Object.keys(chartData),
    };
  
    return  <Chart
                options={_options}
                series={_series}
                type="pie"
                height="350"
              />    
  };

  const ColumnChart = ({ chart }) => {

    let field = chart.fields[0]
    let rowData = []
    for(let i=0;i<allFormsData.length;i++) {
      let data = JSON.parse(allFormsData[i].response)
      rowData.push( data[field])
      
    }

    const chartData = computeOccur(rowData); 
     
    
    var series = [{
      data: Object.values(chartData)
    }]

    var options = {
      chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      }
      },
      // colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: true
      },
      xaxis: {
        categories: Object.keys(chartData),
        labels: {
          style: {
            // colors: colors,
            fontSize: '12px'
          }
        }
      }
    };

  
    return  <Chart
                options={options}
                series={series}
                type="bar"
                height="350"
              />  
  };

  const handleCreateChartModal = () => {
    if(selectedForm != '' ) {
      setShowModal(true);
    }else {
      swal({
        title: "Info",
        text: "Veuillez séléctionner un Projet.",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
      .then((result) => {
        console.log(result)
      });
    }
  }

  const getChartContent = (chart) => {
    switch(chart.type) {
      case 'column':
        return <ColumnChart chart={chart} />;
      case 'line':
          return <LineChart chart={chart} />;
      case 'pie':
        return <PieChart chart={chart}  />;
      case 'scatter':
        return <ScatterChart chart={chart} />;
      default:
        return;
    }
    
  };


  // const ScatterChartProject = useMemo(() => {
  //   return <ScatterProjectchart series={listFormsNb} options={listFormsName} />;
  // }, [listFormsNb, listFormsName]);

  // const chartTopProject = useMemo(() => {
  //   return <Projectchart series={listFormsNb} options={listFormsName} />;
  // }, [listFormsNb, listFormsName]);
  
  // const chartLead = useMemo(() => {
  //   return <Leadchart series={listLeadNb} options={listLeadName} />;
  // }, [listLeadNb, listLeadName]);
  



  // console.log('forms location'+ JSON.stringify(formsLocation.forms.length) )
  return (
    <>
  <Row>
    {/* Inputs Section */}
    <Form className="mt-4 p-3 shadow-lg bg-light rounded">
      <Row>
        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group> 
            <Form.Label><i className="fas fa-user-plus"></i> Responsable</Form.Label>
            <Form.Select  onChange={handleLead} className="shadow-sm dashboard-select">
              <option value=""></option> 
              {listLead &&
                              listLead.map(l => <option value={l._id}>{l.firstName}</option> )
                          }
            </Form.Select>
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}>
          <Form.Group>
            <Form.Label><i className="fas fa-project-diagram"></i> Nom du projet</Form.Label>
            <Form.Select onChange={handleForms} className="shadow-sm dashboard-select">
              <option value=""></option> 
              {listForms &&
                                listForms.map(l => <option value={l._id}>{l.name}</option> )
                            }
            </Form.Select>
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group>
            <Form.Label><i className="fas fa-user"></i> Nom d'utilisateur</Form.Label>
            <Form.Select onChange={handleUser}   className="shadow-sm dashboard-select">
              <option value=""></option> 
                  {user &&
                    user.map(l => <option value={l._id}>{l.firstName}</option> )
                  }
            </Form.Select>
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group>
            <Form.Label><i className="fas fa-calendar-alt"></i> Date de début :</Form.Label>
            <Form.Control 
              type="date" 
              value={startDate} 
              onChange={handleStartDate} 
              className="shadow-sm dashboard-select"
            />
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group>
            <Form.Label><i className="fas fa-calendar-alt"></i> Date de fin :</Form.Label>
            <Form.Control 
              type="date" 
              value={endDate} 
              onChange={handleEndDate} 
              className="shadow-sm dashboard-select"
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  </Row>
                  

  
  {/* Main Content: Left (Boxes and Graphs) and Right (Map) */}
  <Row className="mt-0">
    {/* Left side: Boxes and Graphs */}
    <Col xs={12} xl={12}>
      {/* 4 Counter Boxes */}

      <div className="d-flex justify-content-between  flex-md-nowrap align-items-center py-2">
            <div className="d-block  ">
            
            {/* <h4>Formulaires</h4>
            <p className="mb-0">Créer votre formulaire selon votre besoin.</p> */}
          </div>
          <div className="btn-toolbar mb-md-0" >
          
              <ButtonGroup>
                {/* <Button variant="outline-primary" size="sm">Remplir</Button> */}
                <Button variant="primary" size="sm" onClick={() => handleCreateChartModal()}><FontAwesomeIcon icon={faPlus} className="me-2" />Créer un graphique</Button>
              </ButtonGroup>
           
            
          </div>
        </div>

      {/* Dynamic Graphs Section */}
      <Row>
        {
          chartData.map((chart, index) => {
            return (
              <Col xs={12} sm={5} className="mb-4">
                  <Card border="light" className="shadow-sm">
                      <Card.Header className="border-bottom border-light d-flex justify-content-between dashboard-table">
                        <h5 className="mb-0">{chart.name}</h5>
                      
                      </Card.Header>                
                    {getChartContent(chart)}

                  </Card>
                </Col>
              )
          })
        }
          
      </Row>


      
    </Col>

    {/* Right side: Map */}
    
    </Row>
    <CreateChart show={showModal} handleClose = {handleCloseModal} selectedForm={selectedForm} onCreateChart ={handleCreateChart}  />
    </>
  );
};
