
import React, {useState} from 'react';
import { Row, Col, Card, Container } from '@themesberg/react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import FieldConnectLogo from "../assets/img/logo.png";
import { Link } from 'react-router-dom';
import {  Image, Button, Navbar, Nav, Spinner, Badge } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import {  faTemperatureHigh } from "@fortawesome/free-solid-svg-icons";
import { toast, Toaster } from 'react-hot-toast'; 


export default () => {
    const [emailNewsLetter, setEmailNewsLetter] = useState('');
    const [inLoading, setInLoading] = useState(false)
  
    const handleNewsLetter = (e) => {
      e.preventDefault()
      let reg =   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  
  
      if(emailNewsLetter != "" && reg.test(emailNewsLetter) ) {
        setInLoading(faTemperatureHigh)
        fetch(`/api/newsletter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({emailNewsLetter:emailNewsLetter})}).then(
          response => response.json()
        ).then(
          response => {
              setInLoading(false)
             if(response.status == "success") {
              setEmailNewsLetter("")
              toast.success(response.message, {
                style: {
                  border: '1px solid #713200',
                  padding: '16px',
                }});
             } else {
              toast.error(response.message, {
                style: {
                  border: '1px solid #713200',
                  padding: '16px',
                }});
             }
              
          }
        )
      } else {
        toast.error('Veuillez saisir une adresse email valide.', {
          style: {
            border: '1px solid #713200',
            padding: '16px',
          }});
      }
    }

    return (<>
     <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 d-flex align-items-center">
            <Image style={{height:50}} src={FieldConnectLogo} />
            {/* <span className="ms-2 brand-text d-none d-md-inline">FieldConnect</span> */}
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={HashLink} to="/#presentation" target="_blank">Présentation</Nav.Link>
                <Nav.Link as={HashLink} to="/#features" target="_blank">Fonctionnalités</Nav.Link>
                <Nav.Link as={HashLink} to="/#pricing" target="_blank" className="d-sm-none d-xl-inline">Abonnement </Nav.Link>
                <Nav.Link as={HashLink} to="/#contact" target="_blank">Nous contacter</Nav.Link>
                {/* <Nav.Link as={HashLink} to="#download">Tarification</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
            <Button style={{marginRight: 10, marginLeft: 50}} as={Link} to={Routes.Signin.path} variant="outline-white" className="ms-3">Espace client</Button>
            <Button variant="secondary" as={Link} to={Routes.Signup.path} className="text-dark me-3">
            Essayer gratuitement 
            {/* <FontAwesomeIcon icon={faExternalLinkAlt} className="d-none d-sm-inline ms-1" /> */}
                </Button>
            
          </div>
        </Container>
      </Navbar>
      <Container className="px-0">
        
        <Row>
        <Col xs={12} className="p-3">
            <Card>
            <Card.Body>
                <article>
                <h1 className="h2" id="overview">Politique de confidentialité  

                </h1>
                <br /> <br />
                {/* <p className="fs-5 fw-light">Download files for Volt React Dashboard</p> */}

                

                <h4 id="preambule">Article 1 : PRÉAMBULE </h4>
                <p>
                La présente politique de confidentialité a pour but de rappeler la manière dont sont collectées les données personnelles des utilisateurs, leurs droits y afférents ainsi que l’utilisation de ceux-ci et leurs destinataires.
                </p>
                <p>
                Cette politique complète les mentions légales et les Conditions Générales d’Utilisation consultables par les utilisateurs à l’adresse suivante : <Card.Link as={Link} to={Routes.TermsAndConditions.path}  target="_blank">Terme et Condition d'utilisation</Card.Link>

                </p>

                
                

                <h4 id="collection">Article 2 : Les données collectées    </h4>
                <p>
                Les données personnelles collectées par EFieldConnect sont celles fournies par les Utilisateurs lors de son adhésion à la plateforme ainsi que pendant l’utilisation de celle-ci, notamment le nom, prénom, adresse email, téléphone et le nom de la société.
                Ensuite, EFieldConnect peut également utiliser les informations dont l’Utilisateur autorise en accédant à la plateforme telles que la géolocalisation
                </p>
                <p>
                A noter que cette liste n’est pas exhaustive et dépend de l’utilisation de chacun.
                </p>
                <p>
                La collecte de ces données est importante dans la mesure où elle permet à la plateforme de mener à bien ses missions et de fournir les services attendus par les Utilisateurs. Par ailleurs, celles-ci permettent une amélioration continue de ses services, un développement de nouveaux produits  pouvant être proposés par EFieldConnect par la suite.
                </p>

                <h4 id="utilisation">Article 3 : L’Utilisation des données collectées    </h4>
                <p>
                Les données personnelles sont collectées afin de permettre à EFieldConnect d’exploiter, d’améliorer et de personnaliser ses services selon l’attente de chaque Utilisateur, tout en l’assistant pour mener à bien le partenariat.

                </p>
                {/* <p>
                Ces données peuvent être fournies aux autres utilisateurs pour une collaboration étroite entre eux, qui est la mission principale même de cette plateforme.

                </p> */}
                <p>
                EFieldConnect est susceptible de conserver certaines données à caractère personnel au-delà des délais annoncés ci-dessus afin de remplir ses obligations légales ou réglementaires.

                </p>

           

                 <h4 id="droit">Article 4 : Les droits de l’utilisateur en matière de collecte et de traitement des données      </h4>
                <p>
                Tout utilisateur a le droit d’accès, de rectification et droit à l’effacement des données, le droit à la portabilité des données, le droit à la limitation et à l’opposition du traitement des données, le droit de déterminer le sort des données après la mort, le droit de saisir l’autorité de contrôle compétente.  

                </p>

                <p>
                Pour l’exercice de ces droits, il peut le faire directement via la plateforme ou contacter le délégué ou le responsable du traitement des données pour faire droit à sa demande. Dans ce cas, l’utilisateur peut être tenu de lui communiquer certaines informations telles que : ses noms et prénoms, son adresse e-mail ainsi que son numéro de compte, d’espace personnel ou d’abonné. 
                </p>
                          

                <h4 id="condition">Article 5 : Conditions de modification de la politique de confidentialité </h4>
                <p>
                L’éditeur de la plateforme EFieldConnect se réserve le droit de pouvoir modifier la présente Politique à tout moment afin d’assurer aux utilisateurs du site sa conformité avec le droit en vigueur. 
                </p>

                <p>
                Les éventuelles modifications ne sauraient avoir d’incidence sur les achats antérieurement effectués sur le site, lesquels restent soumis à la Politique en vigueur au moment de l’achat et telle qu’acceptée par l’utilisateur lors de la validation de l’abonnement.  
                </p>
                
                <p>
                L’utilisateur est invité à prendre connaissance de cette Politique à chaque fois qu’il utilise nos services, sans qu’il soit nécessaire de l’en prévenir formellement.
                </p>
                
                </article>
               <p>
               La présente politique est éditée le 09 Aout 2024.
               </p>
            </Card.Body>
            </Card>
        </Col>
        </Row>
    </Container>


    <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 mb-3 d-flex align-items-center">
                
                <Image style={{height:50}} src={FieldConnectLogo} />
              </Navbar.Brand>
              <p>La solution idéale pour vos bésoins de création de formulaire dynamique,  qui peut s'adapter à toutes les spécificités de vos activités de collecte de données sur terrain.</p>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Navigation</span>
              <ul className="links-vertical mt-2">
                <li><Card.Link as={HashLink}  to="/#presentation" target="_blank">Présentation</Card.Link></li>
                <li><Card.Link as={HashLink}  to="/#features" target="_blank">Fonctionnalités</Card.Link></li>
                <li><Card.Link as={HashLink} to="/#pricing" target="_blank">Abonnement</Card.Link></li>
                <li><Card.Link as={HashLink}  to="/#contact" target="_blank">Nous contacter</Card.Link></li>
              </ul>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Autres</span>
              <ul className="links-vertical mt-2">
                <li>
                  {/* <Card.Link as={Link} to={Routes.DocsQuickStart.path} target="_blank">Politique de confidentialité</Card.Link> */}
                </li>
                {/* <li><Card.Link as={Link} to={Routes.DocsChangelog.path} target="_blank">Termes et Conditions</Card.Link></li> */}
                <li><Card.Link  as={Link} to={Routes.TermsAndConditions.path} target="_blank"  >Termes et Conditions</Card.Link></li>
                <li><Card.Link  as={Link} to={Routes.Privacy.path} target="_blank"  >Politique de confidentialité</Card.Link></li>
              </ul>
            </Col>
            <Col xs={12} md={4} className="mb-5 mb-lg-0">
              <span className="h5 mb-3 d-block">La newsletter</span>
              <form action="#">
                <div className="form-row mb-2">
                  <div className="col-12">
                    <input value={emailNewsLetter} onChange={(e) => setEmailNewsLetter(e.target.value)}  type="email" className="form-control mb-2" placeholder="example@company.com" name="email" aria-label="Subscribe form" required />
                  </div>
                  <div className="col-12">
                    <button onClick={(e) => handleNewsLetter(e)} type="submit" className="btn btn-secondary text-dark shadow-soft btn-block" data-loading-text="Sending">
                      <span>
                      {
                      inLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        "Souscrire"
                      )
                    }
                      </span>
                    </button>
                  </div>
                </div>
              </form>
              <p className="text-muted font-small m-0">Souscrivez sur notre Newsletter pour ne pas manquer tous les mises à jours.</p>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © EFieldConnect 2024. Tous droits réservés.</p>
              </div>
              <Card.Link href="https://vntechnology.mg" target="_blank" className="d-flex justify-content-center">
                {/* <Image src={ThemesbergLogo} height={35} className="d-block mx-auto mb-3" alt="Themesberg Logo" /> */}
                <p className="font-weight-normal font-small mb-2">Powered by <span style={{color:"#61DAFB"}} className="ms-2 brand-text">VNTechnology</span>.</p>

              </Card.Link>
            </Col>
          </Row>
        </Container>
      </footer>
      <Toaster   
          position="top-right"
          reverseOrder={true} />
    </>
    );
  
};
