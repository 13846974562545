import { useReactTable } from '@tanstack/react-table';
import React, {useState,useEffect,useContext,useMemo} from "react";
import {Modal,Carousel , Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup, Form } from '@themesberg/react-bootstrap';
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv';
// import { Carousel } from 'react-responsive-carousel';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // nécessite un style de carrousel
import { format } from 'date-fns';
import { useAuth } from '../pages/AuthContext';
import axios from 'axios';
export const FormsTable = ({formId,formsName }) => {
  
const [showModal, setShowModal] = useState(false); 
const [showModalSignature, setShowModalSignature] = useState(false); 
const [showModalFile, setShowModalFile] = useState(false);

const [modalContentFile, setModalContentFile] = useState([])
const handleCloseModalFile = () => setShowModalFile(false);

const [modalContentSignature, setModalContentSignature] = useState()
const handleCloseModalSignature = () => setShowModalSignature(false);

const [modalContent, setModalContent] = useState()
const handleCloseModal = () => setShowModal(false);
const [formsData, setFormsData] = useState([])
const [formsCleaned, setFormsCleaned] = useState([])

const [formsDataExport, setFormsDataExport] = useState([])
const [filterText, setFilterText] = useState('');
const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


const [totalRows, setTotalRows] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(15);
const [sortField, setSortField] = useState('Debut'); // Champ de tri par défaut
const [sortOrder, setSortOrder] = useState('asc'); 
const { token } = useAuth();
const [pending, setPending] = React.useState(true);
const [x, setX] = useState(5);

async function fetchDataForms(){
  const res = await axios.post('/api/getFormsDataTable', {formId :formId,page : currentPage,pageSize : itemsPerPage,sortField,sortOrder}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  let transformedData = res.data.forms
  
  transformedData = transformedData.length == 0 ? []: transformedData.map(item => {
    let flattenedItem = {};
    for (const key in item) {
      const entry = item[key];
      if (entry.id) {
        flattenedItem[entry.id] = entry.value;
      }
    }
    return flattenedItem;
  });
  // console.log('resulates'+ JSON.stringify(transformedData))
  
  setFormsCleaned(transformedData)
  setFormsData(res.data.forms)
  setTotalRows(res.data.totalCount);
};

// console.log('resulates'+ JSON.stringify(formsData))

async function fetchDataFormsExport(){
  const res = await axios.post('/api/getFormsExportDataTable', {formId :formId,currentPage,itemsPerPage,sortField,sortOrder}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  // console.log("(res.data.forms.export",res.data.forms)
  setFormsDataExport(res.data.forms)
};

useEffect( ()=> {
  const timeout = setTimeout(() => {
    fetchDataForms()
    setPending(false);
  }, 2000);
  return () => clearTimeout(timeout);
}, [formId,currentPage, itemsPerPage, sortField, sortOrder])

useEffect( ()=> {
  fetchDataFormsExport()
}, [])


useEffect(() => {
  if (formsData.length === 0) {
    setFormsCleaned([]);
  } else {
    const transformedData = formsData.map(item => {
      let flattenedItem = {};
      for (const key in item) {
        const entry = item[key];
        if (entry.id) {
          flattenedItem[entry.id] = entry.value;
        }
      }
      return flattenedItem;
    });
    setFormsCleaned(transformedData);
  }
}, [formsData]);

const handleShowModal = (link) => {
  console.log('file upload' + JSON.stringify(link) )

    setModalContent(link);
    setShowModal(true);
};

const handleShowSignature = (base64) => {
  console.log('file upload' + JSON.stringify(base64) )

  setModalContentSignature(base64);
  setShowModalSignature(true); // Open the modal
};


const handleShowModalFile = (file) => {
  console.log('file upload' + JSON.stringify(file) )

  setModalContentFile(file);
  setShowModalFile(true); // Open the modal
};


const handlePageChange = (page) => {
  console.log('page selected'+page)
  setCurrentPage(page);
  // fetchDataForms();
};

const handlePerRowsChange = async (newPerPage, page) => {
  setItemsPerPage(newPerPage);
  setCurrentPage(page);
  console.log('page selected'+newPerPage + '=>'+page )
};

const handleSort = (column, sortDirection) => {
  setSortField(column.selector);
  setSortOrder(sortDirection);
};


const handleInputChangeStatus = (id, event) => {
    const newValue = event.target.value;
    // Mettre à jour les données du tableau avec la nouvelle valeur sélectionnée
    // console.log(formsData)
    // console.log(newValue)
     setFormsData((prevData) =>
            prevData.map((item) =>
              item.ID.value === id ? { ...item, check_data: {...item.check_data,value : newValue} } :item
            )
    );

   
      
    fetch('/api/updateStatusDataForms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            {id :id,check_data:newValue}),
      }).then(
        response => response.json()
    )
  
    // console.log(formsData)
  
    // console.log("id"+ id + newValue)
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        backgroundColor: '#1f2334', 
        color : 'white'// You can set your desired background color here
      },
    },
  };



  // console.log("values " + formsData[0])
  const columns = formsData.length == 0 ? []: Object.values(formsData[0]).map((item) => {   
    // Check if item.label is undefined, return an empty object if it is
    if (item.label === undefined || item.label === "" || item.label === "ID") {
        return null;
    }
    // console.log("values " + JSON.stringify(item))
    // Return column definition based on item properties
    const  colmun = {
        name: item.label,
        id: item.id,
        selector : row =>  row[item.id],
        // {
        //     // Handle different element types
        //     if (item.element === 'Camera' && item.value && item.value[0] !== undefined) {
        //         return row[item.id]; // Returning the value for sorting purposes
        //     } else if (item.element === 'check_data') {
        //         return row[item.id]; // Returning the value for sorting purposes
        //     } else {
        //         return row[item.id]; // Returning the value for sorting purposes
        //     }
        // },
        cell: row => {
            // Check if the element is a camera and value is defined
            if (item.element == 'Camera' &&  row[item.id].length > 0) {
                return (
                    <div onClick={() => handleShowModal(row[item.id])} style={{ cursor: 'pointer', textAlign: "center" }}>
                        <i className="fas fa-image"></i>
                    </div>
                );
            // Check if the element is check_data
            } else if (item.element == 'Signature' &&  item.value!= undefined) {
              return (
                  <div onClick={() => handleShowSignature(row[item.id])} style={{ cursor: 'pointer', textAlign: "center" }}>
                      <i className="fas fa-image"></i>
                  </div>
              );
          }else if (item.element == 'FileUpload' &&  row[item.id].length > 0) {  
            return (
              // {row.map((pt, index) => (
              //   // <div key={index}>
              //     <img src={`https://api.e-fieldconnect.com/uploads/files/fileItem_${pt}.jpg`} alt={`Photo ${index}`} />
              //   // </div>
              // ))}


                <div onClick={() => handleShowModalFile(row[item.id])} style={{ cursor: 'pointer', textAlign: "center" }}>
                    <i className="fas fa-file"></i>
                </div>
            );
        // Check if the element is check_data
        } else if (item.element === 'check_data') {
                return (
                    <div>
                        <select 
                            style={{ cursor: 'pointer', textAlign: "center" }} 
                            className={"forms-table " + (row.check_data === 'approuved' ? 'select-approuved' : row.check_data === 'notapprouved' ? 'select-rejected' : 'select-pending')} 
                            value={row.check_data} 
                            aria-label="Default select example" 
                            name="role" 
                            onChange={(event) => handleInputChangeStatus(row.ID, event)}
                        >
                            <option value="">--</option>
                            <option className='select-approuved' value="approuved">Approuvé</option>
                            <option className='select-rejected' value="notapprouved">Rejeté</option>
                            <option className='select-pending' value="pending">En attente</option>
                        </select>
                    </div>
                );
            // Default case for other elements
            } else {
                return <div  style={{ }} >{row[item.id]}</div>;
            }
        },
        sortable: true
    };
    return colmun
}).filter(column => column !== null)



// console.log(transformedData)


const transformedDataToExtract =  formsDataExport.length == 0 ? []: formsDataExport.map(item => {
  let flattenedItem = {};
  for (const key in item) {
    const entry = item[key];
    if (entry.id) {
      flattenedItem[entry.label] = entry.value;
    }
  }
  return flattenedItem;
});

// console.log("transformed" + JSON.stringify(transformedData) )

const generateRange = (x) => {
  const range = [];
  for (let i = 10; i <= x; i+=10) {
    range.push(i);
  }
  return range;
};

const filteredData = useMemo(() => {
    const data = formsData
    // console.log("columns" + JSON.stringify(columns))
    // console.log("filterText" + JSON.stringify(data))
    const mfilterText = filterText;
    const filteredItems = data.filter(
        item =>
          columns.some(column => 
            (item[column.id] && item[column.id].toString().toLowerCase().includes(mfilterText.toLowerCase()))
          )
    );
    return filteredItems
  }, [filterText,columns,formsData]);

  const csvData = useMemo(() => {
    return transformedDataToExtract;
  }, [filteredData]);

  // <CSVLink
  // data={csvData}
  // filename= {"Export_" + formsName + "_"  +  format(new Date(), 'yyyy-MM-dd HH:mm:ss')   +  ".csv"} 
  // className="btn btn-primary"
  // style={{ marginLeft: '10px', height: '32px',padding: '4px'}}
  // >
  // Export csv
  // </CSVLink>

  const subHeaderComponent = useMemo(() => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
        type="text"
        placeholder="Search"
        value={filterText}
        onChange={e => {
          setFilterText(e.target.value);
          setResetPaginationToggle(!resetPaginationToggle);
        }}
      />
        
      </div>
    );
  }, [filterText, resetPaginationToggle,csvData]);

  const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Tous',
  };

return(
 <>
 <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          {formsCleaned.length > 0 &&
          
          <DataTable progressPending={pending} 
                        // title="User List"
                        columns={columns}
                        data={formsCleaned}
                        pagination
                        fixedHeader
                          fixedHeaderScrollHeight="500px"
                          paginationComponentOptions={paginationComponentOptions} 
                        // paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        subHeaderComponent={subHeaderComponent}
                        customStyles={customStyles}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationPerPage={itemsPerPage}
                        // paginationRowsPerPageOptions={generateRange(totalRows) }
                        // onSort={handleSort}
                        // sortServer

                        // actions={actionsMemo}
                />
          }
       
        </Card.Body> 
    </Card> 

    <Modal show={showModal} onHide={handleCloseModal} animation={false}>
      <Modal.Header closeButton>
        <h6>{Array.isArray(modalContent) ? 'Photo Gallery' : 'Photo'}</h6>
      </Modal.Header>
      <Modal.Body>
        {
          Array.isArray(modalContent) && modalContent.length > 1 ? (
            <Carousel>
              {modalContent.map((pt, index) => (<Carousel.Item key={index}>
                // <div key={index}>
                  <img src={`https://api.e-fieldconnect.com/uploads/items/imageItem_${pt}.jpg`} alt={`Photo ${index}`} />
                // </div>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <img src={`https://api.e-fieldconnect.com/uploads/items/imageItem_${modalContent}.jpg`} alt="Photo" />
          )
        }
      </Modal.Body>
    </Modal>

    <Modal show={showModalSignature} onHide={handleCloseModalSignature} animation={false}>
      <Modal.Header closeButton>
        Signature
      </Modal.Header>
      <Modal.Body>
          <img src={"data:image/jpeg;base64," + modalContentSignature} alt="Image" />
      </Modal.Body>
    </Modal>
<Modal show={showModalFile} onHide={handleCloseModalFile} animation={false}>
  {/* <Modal.Header closeButton>
    <h6>{Array.isArray(modalContentFile) ? 'Photo Gallery' : 'Photo'}</h6>
  </Modal.Header> */}
    {modalContentFile.length >= 1 ? (
      <div>
      <Modal.Header closeButton>
         Upload
      </Modal.Header>
      <Modal.Body>
      <Carousel> 
         {
         modalContentFile.map((pt,index) => (
           <Carousel.Item key={index}>
              {
              pt && pt.type === 'jpg' ? (
                    <img
                      src={`https://api.e-fieldconnect.com/uploads/files/fileItem_${pt.url}`}
                      alt={`Photo ${index}`}
                      className="d-block w-100"
                    />
                
                ):(<Card key={index} style={{ marginBottom: '10px' }}>
                <Card.Body>
                  <Card.Title>{pt.name}</Card.Title>
                  <a href={`https://api.e-fieldconnect.com/uploads/files/fileItem_${pt.url}`} target="_blank" rel="noopener noreferrer">
                    Télécharger
                  </a>
                </Card.Body>
              </Card>) } 
                </Carousel.Item>
                )
              )
              }
        </Carousel>
      </Modal.Body>
      </div>
    ) : (
      modalContentFile[0] && modalContentFile[0].type === 'jpg' && (
        <img
          src={`https://api.e-fieldconnect.com/uploads/files/fileItem_${modalContentFile[0].url}`}
          alt="Photo"
          className="d-block w-100"
        />
      )
    )}
</Modal>




    
  </>
)
 
};



 