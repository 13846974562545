import React, { useState,useContext, useEffect,useRef } from 'react';

import { render } from 'react-dom';
import { useHistory, useLocation ,useParams,Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArchive,faEye , faEllipsisH,faUserSlash, faUserCheck, faBan ,faHome,faPlus,faEdit,faTrashAlt} from "@fortawesome/free-solid-svg-icons";
 
import { ButtonGroup,Dropdown ,Breadcrumb,Button,Col, Row , Nav, Card, Table, Pagination,Form } from '@themesberg/react-bootstrap';

import EmailEditor from 'react-email-editor';
import { useAuth } from './AuthContext';

export default () => {
  const emailEditorRef = useRef(null);
  const { canalId } = useParams();
  const { token } = useAuth();
  const userCon =  JSON.parse(localStorage.getItem("user"))
  const history = useHistory()

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const { design, html } = data;
    fetch("/api/updateCanal/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({canalId,designContent: design,htmlContent :html  }),
    }).then(
        response => response.json()
    ).then(
      // data => console.log(data)
        data => {

          if(data.status === "success") {
          //   console.log('my prospecting fata' +data['prospecting']._id)
          //  history.push({pathname: `/create-forms/${data['prospecting']._id}`,state: {params}});
          swal({
            title: "Canal ",
            text:  "Canal sauvgardé avec succes",
            icon: "success",
            buttons: true,
            dangerMode: false,
          })
          .then((result) => {
            console.log(result)
            history.push("/canal-project");

          });

          }else if(data.status === "session_expired") {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('user')
            swal({
              title: "Session expirée",
              text: data.message,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              console.log(result)
            });
            this.props.history.push("/sign-in");
          }else {
            swal({
              title: "Erreur",
              text: data.message,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              console.log(result)
            });
          }
        }
        
    )

    });
  };

  const onReady = (unlayer) => {
    fetch("/api/getCanal/", { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({canalId : canalId,userId: userCon._id }),
    }).then(
        response => response.json()
    ).then(
        data => {
          const design  = data.canal.designContent;
          unlayer.loadDesign(design);
        }
    ).catch((error) => console.error('Error loading design:', error));
  };

  useEffect(() => {
  
    fetch("/api/getAllCanalMobile/", { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({userId : userCon._id}),
    }).then(
        response => response.json()
    ).then(
        data => {
        }
    ).catch((error) => console.error('Error loading design:', error));
    }, [canalId])

  return (
    <>
    <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} />FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/canal-project' }}>Canaux</Breadcrumb.Item>
            <Breadcrumb.Item active>Edit canal</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
    <div>
      <div>
        {/* <button onClick={exportHtml}>onClick={exportHtml}</button> */}
        <Button variant="primary" size="sm" onClick={exportHtml}>Sauvegarder</Button>
      </div>

      <EmailEditor ref={emailEditorRef} onReady={onReady}  options={{
                                                locale: 'fr', // Set the language to French
                                                appearance: {
                                                  theme: 'dark',
                                                },
  }}/>
    </div>
    </>
  );
};
// onReady={onReady}
// render(<App />, document.getElementById('app'));
