import React, {useState,useEffect} from "react";
import {Modal ,Form ,Button,Col, Row,Toast  } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert';
import { useAuth } from '../pages/AuthContext';
import ScaleLoader from "react-spinners/ScaleLoader";



export const CreateChart = ({ show, handleClose,selectedForm, onCreateChart }) => {
  const history = useHistory()
  const { token } = useAuth();
  let [loading, setLoading] = useState(false);
  let [saving, setSaving] = useState(false);
  const [chartName, setChartName] = useState('');
  const [chartType, setChartType] = useState('');
  const [chartFields, setChartFields] = useState([]);
  const [fieldNameOptions,setFieldNameOptions] = useState([])
  const [user, setUser] = useState([])


  console.log(chartName, chartType, chartFields)

  const getFieldsForChart = () => {
    
    let userCon =  localStorage.getItem("user")

    if(userCon !== null) {
      console.log("tonga ato: ", selectedForm)
      
      if(selectedForm != null && selectedForm != ""){
        setLoading(true)
        userCon =  JSON.parse(userCon)
        fetch(`/api/getFieldsForChart/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({userId :userCon._id,formId:selectedForm,chartType})
        }).then(response => response.json())
          .then(
          data => {
            setLoading(false)
            if(data.status === "success") {
              // console.log('all forms '+data)
              let fields_values = data["fields"].map((option) => ({label: option.label, value: option.id}));
              console.log(fields_values)
              setFieldNameOptions(fields_values)
            }else if(data.status === "session_expired") {
              localStorage.removeItem('accessToken')
              localStorage.removeItem('user')
              swal({
                title: "Session expirée",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
              history.push("/sign-in");
            }else {
              swal({
                title: "Erreur",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
            }
          }
        );
      }
      
    }else {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
      swal({
        title: "Session expirée",
        text: "Votre session a expirée.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((result) => {
        console.log(result)
      });
      history.push("/sign-in");
    }
  }


  useEffect(() => {
    
    getFieldsForChart()
  }, [chartType]);


  const chartTypeOptions = [
    {
      label: "Histogramme",
      value: "column"
    },
    {
      label: "Ligne",
      value: "line"
    },
    {
      label: "Circulaire",
      value: "pie"
    },
    {
      label: "Nuage de points",
      value: "scatter"
    }
  ];


    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setChartName(value);
    }

    const handleChangeType = (selectedOption) => {
      let selectedValue;
      let name = "type"
      if(selectedOption === null ){
        selectedValues = ''
      }else{
         selectedValue = selectedOption.value
      }
      
      setChartType(selectedValue);
    };

    const handleChangeField = (selectedOptions) => {
      let selectedValues;
      let name = "fields"

      if(selectedOptions === null ){
        selectedValues = []
      }else{
         selectedValues = selectedOptions.map((option) => option.value);
      }
      setChartFields(selectedValues);
    };

    const saveNewChart = () => {
      let userCon =  localStorage.getItem("user")

      if(userCon !== null) {
        
        if(selectedForm != null && selectedForm != ""){
          setSaving(true)
          userCon =  JSON.parse(userCon)
          fetch(`/api/saveNewChart/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({chartName, chartType, chartFields, userId :userCon._id,formId:selectedForm})
          }).then(response => response.json())
            .then(
            data => {
              setSaving(false)
              if(data.status === "success") {
                // console.log('all forms '+data)
                setChartName('')
                setChartType('')
                setChartFields([])
                onCreateChart()
              }else if(data.status === "session_expired") {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                swal({
                  title: "Session expirée",
                  text: data.message,
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((result) => {
                  console.log(result)
                });
                history.push("/sign-in");
              }else {
                swal({
                  title: "Erreur",
                  text: data.message,
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((result) => {
                  console.log(result)
                });
              }
            }
          );
        }
        
      }else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('user')
        swal({
          title: "Session expirée",
          text: "Votre session a expirée.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((result) => {
          console.log(result)
        });
        history.push("/sign-in");
      }
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      // const { teamName, desc} = { ...formData};
      saveNewChart()
      handleClose();
    };



    return( 
      <>
        <Modal  centered show={show} onHide={handleClose} animation={false}  backdrop="static" > 
        <Modal.Header>
          <Modal.Title className="h6">Créer un graphique</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form >
              <Row>
                  <Form.Group>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control  name="name"  onChange={handleInputChange}  required type="text"/>
                  </Form.Group>
              </Row>
              
              <Row>
                  <Form.Group>
                    <Form.Label>Type</Form.Label>

                  <Select
                    
                    options={chartTypeOptions}
                    onChange={handleChangeType}
                    placeholder="Sélectionnez..."
                  />
                  </Form.Group>
              </Row>
              <Row>
              {
                loading  ? (
                  <div style={{padding:10, textAlign:"center"}}>
                    <ScaleLoader color="#4A5073" />
                  </div>
                  
                ): (
                  
                    <Form.Group>
                      <Form.Label>Nom du Champ</Form.Label>

                    <Select
                      isMulti
                      options={fieldNameOptions}
                      onChange={handleChangeField}
                      placeholder="Sélectionnez..."
                    />
                    </Form.Group>
                
                )
              }
              </Row>
              
          </Form>
         
        </Modal.Body>
          <Modal.Footer>
            <Button  variant="link" className="text-gray ms-auto" onClick={handleClose}>
              Annuler
            </Button>
            <Button disabled={chartName == '' || chartType == '' || chartFields.length == 0} variant="secondary" type="submit" onClick={handleSubmit}>
              { saving  ? (
                  <div style={{ textAlign:"center"}}>
                    <ScaleLoader height={25} color="#4A5073" />
                  </div>
                  
                ):(
                  "Créer"
                )
              }
            </Button>     
          </Modal.Footer>
      </Modal>
      </>
    )   
}

// export default Modaly