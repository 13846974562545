import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.fullscreen';
import 'leaflet.heat';

const MapAdvanced = (props) => {
  const mapRef = useRef(null);
  const heatmapLayerRef = useRef(null);
  const markersRef = useRef(null); // Ref pour le cluster de marqueurs
  const [mapInstance, setMapInstance] = useState(null);

  console.log('test'+JSON.stringify(props.id))
  const getClusterColor = (count) => {
    if (count < 10) {
      return 'green'; // Faible nombre de marqueurs
    } else if (count < 50) {
      return 'orange'; // Nombre moyen de marqueurs
    } else { 
      return 'red'; // Grand nombre de marqueurs
    }
  };

  useEffect(() => {
    const map = L.map(mapRef.current).setView([-18.8944862, 47.505461], 6);

    const osmLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
    osmLayer.addTo(map);

    L.control.fullscreen().addTo(map);
    setMapInstance(map);

    return () => {
      map.remove();
    };
  }, []);

  // Nettoyage et ajout des couches sur changement de props
  useEffect(() => {
    if (mapInstance && props.marker?.forms) {
      // Clear des anciennes couches si elles existent
      if (markersRef.current) {
        markersRef.current.clearLayers();
      }
      if (heatmapLayerRef.current) {
        mapInstance.removeLayer(heatmapLayerRef.current);
      }

      // Création d'un nouveau groupe de clusters
      markersRef.current = L.markerClusterGroup({
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: false
        // maxClusterRadius: 40,
        // iconCreateFunction: (cluster) => {
        //   const count = cluster.getChildCount();
        //   const color = getClusterColor(count);
          
        //   return L.divIcon({
        //     html: `<div style="background-color: ${color}; border-radius: 50%; padding: 10px; color: white;">${count}</div>`,
        //     className: 'custom-cluster-icon',
        //     iconSize: L.point(40, 40)
        //   });
        // }
      });

      const heatmapPoints = [];

      // Ajout des marqueurs et des points pour la heatmap
      props.marker.forms.forEach(m => {
        const latLng = [m.latitude, m.longitude];
        const marker = L.marker(latLng);

        const popupContent = `
          <div>
            <strong>Projet :</strong> ${m.prospectingId.name}<br>
            <strong>Utilisateur :</strong> ${m.user ? m.user.name : ''}<br>
            <strong>DateStart :</strong> ${m.startDate}
          </div>
        `;

        marker.bindPopup(popupContent);

        // Ajout des marqueurs dans le cluster
        markersRef.current.addLayer(marker);

        // Ajout des points pour la heatmap
        heatmapPoints.push([...latLng, 0.5]);
      });

      // Ajout du cluster et de la heatmap à la carte
      mapInstance.addLayer(markersRef.current);
      
      // radius: 10,
      //     blur: 2,
      //     maxZoom: 20,
      if (heatmapPoints.length > 0) {
        heatmapLayerRef.current = L.heatLayer(heatmapPoints, {
          radius: 30, // Increase radius for larger points
          blur: 20,   // Increase blur for smoother heatmap
          maxZoom: 17,
          // maxOpacity: 0.9,
          gradient: {
            0.1: 'blue',
            0.5: 'lime',
            0.9: 'yellow' 
          }
        }).addTo(mapInstance);
      }
    }
  }, [props.marker, mapInstance]);

  return (
    <div>
      <div id= {props.id}  ref={mapRef} style={{ height:props.height, width: props.width }}></div>
    </div>
  );
};

export default MapAdvanced;
